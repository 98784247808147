.navbar {
    background-color: $black;
    padding: 0;
    .container-fluid {
        padding-right: 0;
        .navbar-brand {
            display: block;
            margin: 5px auto !important;
            @include media-breakpoint-up(md) { 
                margin-left: 3.2rem !important;
                } 
            }
        }
        
    .navbar-nav {
        .nav-item {
            a {
                text-transform: uppercase;
            }
        }
        li {
            color: $white;
            border: 1px solid $white;
            border-top: 0px;
            border-bottom: 0px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-self: center;
            align-items: center;
            a {
                display: flex;
                justify-content: center;
                align-content: center;
                align-self: center;
                align-items: center;
                height: 8.2rem;  
                padding: 3rem;
                
            }
            &:nth-child(1),
            &:nth-child(2) {
                .nav-link {
                    font-size: 1.5rem;
                &:hover {
                    background-color: $red;
                    transition: .3s all ease;
                    }
                }
            }
            &:nth-child(3) {
                &:hover {
                    background-color: $red;
                    transition: .3s all ease;
                    }
            }
            &:nth-child(4) {
                background-color: $white;
                color: $black;
                border: 0px;
                transition: .3s all ease;
                &:hover {
                    background-color: $red; 
                    color: $white;
                    transition: .3s all ease;
                    border-right: 1px solid $white;
                }
            }
            &:nth-child(5) {
                background-color: $red;
                color: $white;
                border:0px;
                transition: .3s all ease;
                &:hover {
                    background-color: $white !important;
                    color: $black;
                    transition: .3s all ease;
                    border-left: 1px solid $red;
                }
            }
            .nav-link {
                font-size: 1.2rem;	
                letter-spacing: 0.4rem;	
                line-height: 1.5rem;	
                text-align: center;
                padding: 3rem;
            }
        }
    }
}
