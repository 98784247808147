html {  font-size: 62.5%;  }
body {  font-size: 1.6rem; line-height:1.5rem; }

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin crossbrowsershadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin crossbrowserclass($transition, $easing, $delay) {
  -webkit-transition: $transition $delay $easing;
  -moz-transition: $transition $delay $easing;
  -o-transition: $transition $delay $easing;
  transition: $transition $delay $easing;
}

@mixin crossbrowserdelay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin mediaqueryvalue($attribute,$values){
  @each $mediaquery, $i in $values {
    @include media-breakpoint-up($mediaquery) {
      @each $att in $attribute {
        #{$att}:$i;
      }
    }
  }
}

@mixin animfx($name, $speed, $attribute, $easing, $delay, $from, $to) {
  //normal onload animation
  @include crossbrowserclass($name $speed, $easing, $delay);
  @include keyframes($name) {
    from {
      #{$attribute}: $from;
    }
    to {
      #{$attribute}: $to;
    }
  }
}

@mixin emergencefx($name, $speed, $attribute, $easing, $delay, $from, $to) {
  //animation activated with emergence and reset when off screen
  &[data-emergence=visible] {
    @include crossbrowserclass($name $speed, $easing, $delay);
    @include keyframes($name) {
      from {
        #{$attribute}: $from;
      }
      to {
        #{$attribute}: $to;
      }
    }
  }
  &[data-emergence=hidden] {
    #{$attribute}: $from;
  }
}

@mixin animtwinfx($name, $speed, $attribute,$attribute2, $easing, $delay, $from, $to, $from2, $to2) {
  //TODO: use scss loop for multiple css
  @include crossbrowserclass($name $speed, $easing, $delay);
  @include keyframes($name) {
    from {
      #{$attribute}: $from;
      #{$attribute2}: $from2;
    }
    to {
      #{$attribute}: $to;
      #{$attribute2}: $to2;
    }
  }
}

@mixin emergencetwinfx($name, $speed, $attribute,$attribute2, $easing, $delay, $from, $to, $from2, $to2) {
  //TODO: use scss loop for multiple css
  &[data-emergence=visible] {
    @include crossbrowserclass($name $speed, $easing, $delay);
    @include keyframes($name) {
      from {
        #{$attribute}: $from;
        #{$attribute2}: $from2;
      }
      to {
        #{$attribute}: $to;
        #{$attribute2}: $to2;
      }
    }
  }
  &[data-emergence=hidden] {
    #{$attribute}: $from;
    #{$attribute2}: $from2;
  }
}


@mixin transition-all() {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin serif-font() {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}

@mixin sans-serif-font() {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

@mixin blurfx($blurcolor,$colorfx,$size) {
  color: $blurcolor;
  animation: blur .75s ease-out;
  @keyframes blur {
    from {
      text-shadow: 0px 0px ($size*2) $blurcolor,
      0px 0px ($size*2) $blurcolor,
      0px 0px ($size*5) $blurcolor,
      0px 0px ($size*5) $blurcolor,
      0px 0px ($size*5) $blurcolor,
      0px 0px ($size*5) $blurcolor,
      0px 0px ($size*5) $blurcolor,
      0px 0px ($size*5) $blurcolor,
      0px 0px ($size*10) $blurcolor,
      0px 0px ($size*10) $blurcolor,
      0px 0px ($size*10) $colorfx,
      0px ($size*2) ($size*20) $colorfx,
      0px ($size*2) ($size*20) $colorfx,
      0px ($size*2) ($size*20) $colorfx,
      0px ($size*2) ($size*20) $colorfx,
      0px -($size*2) ($size*20) $colorfx,
      0px -($size*2) ($size*20) $colorfx;
    }
    to {
      text-shadow: 0px 0px 0px $blurcolor;
    }
  }
}

@mixin colorTocolor($from,$to,$duration) {
  color:$from;
  @keyframes fromto {
    0% {
      color: $from;
    }
    100% {
      color: $to;
    }
  }
  animation-name: fromto;
  animation-duration: $duration;
}

@mixin bgcolorTobgcolor($from,$to,$duration) {
  @keyframes bgfromto {
    0% {
      background-color: $from;
    }
    100% {
      background-color: $to;
    }
  }
  animation-name: bgfromto;
  animation-duration: $duration;
}

@mixin underliner($color,$size,$distance) {

  display: inline-block;
  position: relative;
  padding-bottom: $size;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: $distance;
    height: $size;
    width: 0;
    transition: width 0s ease, background .5s ease;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: $distance;
    height: $size;
    width: 0;
    background: $color;
    transition: width .5s ease;
  }
  &:hover:before {
    width: 100%;
    background: $color;
    transition: all .5s ease;
  }
  &:hover:after {
    width:  100%;
    background: transparent;
    transition: all 0s ease;
  }

}

@mixin ratioheight($ratiowidth,$ratioheight) {
  width: 100%;
  height: (($ratioheight*100)/$ratiowidth)+0vw;
}

@mixin vertical-align-center($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


//////////////////////////////////---TYPOGRAPHY---///////////////////////////////
//The full article about this sass mixin and technique can be found here:
//https://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
// Because all browsers have a default font-size of 16px,
// setting the font-size to 62.5% on the html element gives it a font-size of 10px (10 / 16 * 100 = 62.5)
// without explicitely setting it to 10px which would prevent zooming.
// Then, setting a font-size of 1.6rem on the body element
// simply results in a font-size of 16px, cascading through the whole DOM tree.

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
// The mixin checks to see whether the value of the key in the font-sizes map is a list
// as opposed to a font-size value. If it’s a list, then it gets the correct value from the list by index value,
// with the help of the nth function. It assumes that the first value is the font size and the second is the line height.

@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}

//Now, what if we want an element to have a font size for a custom breakpoint that doesn’t exist in $breakpoints?
//In the font-sizes maps (the maps can be found and tweaked in _typo-configs.scss ), simply drop in the breakpoint value instead of a name
//as the key, and the mixin will do the work for you


