.mainfooter {
	.logo__footer {
		display: block;
		width: 25rem;
		max-width: 100%;
	}
	.footer-top {
		padding: 6rem 0;
		.footer__btn {
			.btn-cta {
				&:after {
					display: none;
				}
			}
		}
	}
	.footer-middle {
		.footer__social,
		.footer-pad {
			h5 {
				width: 60%;
				padding: 1rem 0 15px;
				margin: 0;
				font-size: 12px;
				letter-spacing: 3.75px;
				line-height: 2rem;
				color: $black;
				text-align: left !important;
                text-transform: uppercase;
                a {
                    &:hover {
                        color: $red;
                        transition: width 0.4s ease;
                    }
                }
			}
			span {
				font-size: 1.2rem;
				font-family: "Eczar";
				letter-spacing: 1px;
				line-height: 18px;
			}
			.section__heading>a {
				padding: 1rem 0 1.5rem;
				margin: 0;
				font-size: 1.2rem;
				letter-spacing: 3.75px;
				line-height: 2rem;
				color: $black;
				text-align: left !important;
				text-transform: uppercase;
			}
			span {
				@extend .section__heading;
			}
			.footer__cert-list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				.footer__cert-item {
					margin: 1rem 2rem 1rem 0;
					img {
						width: 4.3rem;
						max-width: 100%;
					}
				}
			}
		}
		.footer__social-list {
			.footer__social-item {
				display: inline-block;
				width: 28px;
				height: auto;
				margin-right: 5px;
				.footer__social-link {
					.svg-inline--fa {
						font-size: 2rem;
						color: $red;
					}
				}
			}
		}
		.footer__list {
			list-style: none;
			padding: 0;
			.footer__items {
				.footer__link {
					font-size: 12px;
					letter-spacing: 1px;
					line-height: 18px;
					color: $black;
					font-family: "Eczar";
					text-decoration: none;
					&:hover {
						color: $red;
						transition: width 0.4s ease;
					}
				}
			}
		}
	}
	.footer-bottom {
		padding: 7rem 0 0;
		.list {
			padding: 0;
			list-style: none;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
			.list__item,
			.lang-item {
				padding: 1rem;
				font-size: 12px;
				letter-spacing: 3.75px;
				line-height: 2rem;
				color: #080708;
				text-decoration: none;
				font-family: "Larsseit";
				text-transform: uppercase;
				a {
					font-size: 12px;
					letter-spacing: 3.75px;
					line-height: 2rem;
					color: $black;
					text-decoration: none;
					font-family: $headings-font-family;
					text-transform: uppercase;
				}
			}
		}
		.minimal {
			background-image: url('/wp-content/themes/controlerl/assets/img/minimalMTL_logo.png');
			background-repeat: no-repeat;
			background-position: left;
			background-size: 29px;
			display: inline-block;
			padding-left: 27px;
			font-size: 1rem !important;
		}
	}
}