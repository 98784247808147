.btn-cta {
    font-size     : $default-font-size;
    letter-spacing: 0.3rem;
    line-height   : 1.8rem;
    border-radius   : 0;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    text-transform: uppercase;
    white-space: normal;
    max-width: 100%;
    outline: none;
    @include media-breakpoint-up(lg) {
        letter-spacing: 0.5rem;
    }
    &:focus,
    &:active,
    &:visited {
        outline     : none;
    }
    &.has__bg-red {
        background-color: $red;
        padding: 2rem 0;
        width: 100%;
        text-transform: uppercase;
        &:after {
            display: none;
        }
    }
    &.has__color-white {
        color: $white;
    }
    &.has__color-black {
        color: $black;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: -1rem;
        z-index: 1;
        left: 0;
        width: 2.8rem;
        height: 0.3rem;
        background-color: $red;
        -webkit-transition: width 0.3s ease;
        transition: width 0.3s ease;
        
    }
    &:hover::after {
        width: 100%;
        -webkit-transition: width 0.3s ease;
        transition: width 0.3s ease;
    }
}
.btn__cta-big {
    background-color: $red;
    font-size       : 3.75rem;
    text-align      : center;
    color           : white;
    padding         : 0 2rem;
    border-radius   : 0;
    &:focus,
    &:active,
    &:visited {
        outline     : none;
    }
}

button {
    background-color: transparent;
    border-radius   : 0;
    &.btn-cta {
        &.has__color-white {
            color: $white;
        }
        &.has__color-black {
            color: $black;
        }
    }
}

