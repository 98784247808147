.section__map {
    .acf-map {
        height: 40rem;
            @include media-breakpoint-up(md) {
                height: 60rem;
            }
    }
    .block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: flex-start;
        }
        .block__contact {
            margin: 5%;
        }
    }
}

.block__flex {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 5rem 1.5rem;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding: 5rem 1.5rem;
        align-self: center;
    }
    .block{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-self: center;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 35%;
        }
        .block__contact {
            display: block;
            margin: 5% auto;
            text-align: center;
            .texte {
                p,a {
                    font-size: 1.6rem !important;	
                    letter-spacing: 0.1rem !important;	
                    line-height: 2.1rem !important;	
                    text-align: center !important;
                }
            }
        }
        .block__social {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-self: center;
            padding-top: 2rem;
            @include media-breakpoint-up(lg) {
                padding-top: 4rem;
            }
            .social__list {
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                list-style: none;
                padding: 0;
                .social__item {
                    padding:  0 2rem;
                    .social__link {
                        color: $red;
                    }
                }
            }
        }
    }
    .block:nth-child(even) {
        .block__contact:nth-child(odd) {
            margin:5% auto;
            @include media-breakpoint-up(lg) {
                margin-bottom: 7rem;
            }

        }
    }
}