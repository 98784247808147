.section__projects {
    background-color: $black;
}
.has__bg-beige {
    background-color: $beige;
}

.has__bg-black {
    background-color: $black;
}

.has__bg-white {
    background-color: $white;
}

.has__bg-red {
    background-color: $red;
}