a,
a:visited,
a:hover,
a:active {
	color: inherit;
	text-decoration: none;
}

.outer-menu {
	position: fixed;
	/*top: 6.2rem;
    left: 0;
    z-index: 1;*/
	z-index: 999999;
	top: 0;
	right: 0;
	background-color: $white;
	width: 8rem;
	/*height: 8rem;*/
	height: 6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	@include media-breakpoint-up(md) {
		width: 7rem;
		height: 7rem;
	}
	@include media-breakpoint-up(lg) {
		width: 8.5rem;
		height: 8.5rem;
		top: 8.2rem;
		left: 0;
		z-index: 1;
	}
	.checkbox-toggle {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		cursor: pointer;
		width: 6rem;
		height: 6rem;
		opacity: 0;
		transform: translate(-50%, -50%);
		color: $black;
		background-color: $black;
		&:checked {
			&+.hamburger {
				div {
					transform: rotate(135deg);
					&:before,
					&:after {
						top: 0;
						transform: rotate(90deg);
					}
					&:after {
						opacity: 0;
					}
				}
			}
			&~.menu {
				pointer-events: auto;
        		visibility: visible;
        		width: 100%;
        		transition-duration: .2s;
				div {
					transform: scale(1);
          			transition-duration: .3s;
          			opacity: 1;
					div {
						opacity: 1;
						transition: opacity $anim-duration ease $anim-duration;
					}
				}
			}
		}
		&:checked:hover+.hamburger>div {
			transform: rotate(225deg);
		}
	}
	.hamburger {
		z-index: 1;
		width: 6rem;
		height: 6rem;
		padding: .5em 1em;
		border-radius: 0 .12em .12em 0;
		cursor: pointer;
		transition: box-shadow $anim-duration ease;
		backface-visibility: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			position: relative;
			flex: none;
			width: 100%;
			height: .2rem;
			background-color: $black;
			transition: all $anim-duration ease;
			display: flex;
			align-items: center;
			justify-content: center;
			&:before,
			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				top: -$hamburger-menu-size / 6;
				left: 0;
				width: 100%;
				height: .2rem;
				background-color: $black;
				transition: all $anim-duration ease;
			}
			&:after {
				top: 1rem;
			}
		}
	}
	.menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		pointer-events: none;
		visibility: hidden;
		overflow: hidden;
		backface-visibility: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
    	background-color: $red;
    	transition: 0.2s;
    
		.overlay {
			color: $white;
			text-align: left;
			width: 100%;
			position: relative;
      		top: 5rem;
      		transition: 0.3s;
      		opacity: 0;
			@include media-breakpoint-up(md) {
				top: 3rem;
				width: 70%;
			}
			backface-visibility: hidden;
			overflow: hidden;
			display: flex;
			justify-content: flex-start;
      		flex-direction: column;
			.menu__list {
				list-style: none;
        		display: block;

				li {
					padding: 0;
					margin: 1rem 0;
					padding: 1rem 0;
					display: block;
					@include media-breakpoint-up(md) {
						padding: 1.5rem 0;
					}
					@include media-breakpoint-up(lg) {
						padding: 2rem 0;
					}
					a {
						position: relative;
						display: inline;
						cursor: pointer;
						transition: color $anim-duration ease;
						font-size: 2rem;
						text-align: left;
						@include media-breakpoint-up(md) {
							font-size: 3.5rem;
						}
						@include media-breakpoint-up(lg) {
							font-size: 5rem;
						}
						&:hover {
							color: $hover-color;
							&:after {
								width: 100%;
							}
						}
						&:after {
							content: '';
							position: absolute;
							z-index: 1;
							bottom: -.15em;
							left: 0;
							width: 3.5rem;
							height: .2rem;
							background-color: $hover-color;
							transition: width $anim-duration ease;
						}
					}
				}
			}
			.block {
				display: flex;
				flex-direction: row;
				justify-content: center;
				padding: 1rem 0;
				width: 90%;
				@include media-breakpoint-up (md) {
					flex-direction: row;
					justify-content: space-between;
				}
				.block__lang {
					display: inline-flex;
					@include media-breakpoint-up(lg) {
						display: block;
					}
				}
				.block__lang,
				.block__contact,
				.block__social {
					.lang__list,
					.contact__list,
					.social__list {
						display: inline-flex;
						list-style: none;
						li {
							margin: 0 2rem 0 0;
							line-height: 0.1rem;
							.svg-inline--fa {
								font-size: 2.4rem;
							}
						}
					}
					.contact__list {
						display: inline-flex;
						@include media-breakpoint-up(lg) {
							display: none;
						}
					}
				}
			}
		}
	}
}
