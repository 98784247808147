.section__heading {
  //  margin-bottom: 6rem;
}

.section__heading-span {
    color: $white;
    text-align: center;
    display: block;
    
}

.section__heading-h2 {
    line-height: 5rem;
    text-align: left;
    color: $white;
    &.has__bg-red {
        background-color: $red;
        color: $white;
        padding: 2rem;
        display: table;
        text-align: left;
        &:nth-child(1) {
            margin-bottom: 2rem;
        }
        &:nth-child(2) {
            margin-bottom: 2rem;
        }
        &:nth-child(3) {
            margin-bottom: 2rem;
        }
        &.has__marg {
            margin: 0 auto 2rem;
        }
    }
    &.has__bg-black {
        background-color: $black;
        color: $white;
        padding: 2rem;
        display: table;
        text-align: left;
        &:nth-child(1) {
            margin-bottom: 2rem;
        }
        &:nth-child(2) {
            margin: 0;
        }
        &:nth-child(3) {
            margin-top: 2rem;
        }
        &.has__marg {
            margin: 0 auto 2rem;
        }
    }
}

.section__heading-h4 {
    letter-spacing: 2.7px;
    line-height: 24px;
    color: $red;
}

.section__heading-h5 {
    line-height: 24px;
    text-align: center;
}

.section__subheading {
    color: $white;
    line-height: 3rem;
    text-align: center;
}

.section__description {
    font-size: 18px;
    line-height: 3rem;
    color: $black;
}

.block__text {
    padding: 12rem 0;
    .lead {
        line-height: 5rem;
        text-align: center;
        color: $black;
    }
    &.bg-black {
        background-color: $black;
        padding: 14rem 0;
        .section__heading-h2 {
            margin-bottom: 25px;
        }
        .section__subheading {
            margin-top: 25px;
        }
        .section__heading-h5 {
            color: $gray;
            margin-bottom: 16px;
        }
        .btn-cta {
            margin-top: 16px;
        }
    }
}