img {
	width: 100%;
	height: auto;
}


#controlerl-wrapper {
	padding-top: 4rem;
	@include media-breakpoint-up(lg) {
		padding-top: 8rem;
    }
    img {
        max-width: 100%;
        width: 100%;
	}
	:focus {
		outline: none !important;
	}
}

.section__even {
	img {
		max-width: 100%;
		object-fit: cover;
		display: block;
		margin-right: auto;
	}
}

.section__odd {
	img {
		max-width: 100%;
		object-fit: cover;
		display: block;
		margin-left: auto;
	}
	h4 {
		text-align: center;
		margin-top: 25px;
	}
	a {
		text-align: center;
	}
}

.section__map {
	position: relative;
	iframe {
		position: absolute;
		left: 0;
	}
	.col-lg-6:nth-child(2) {
		padding: 14rem 2rem;
		.block__contact {
			margin: 5%;
			text-align: center;
			.section__heading-h5 {
				margin-bottom: 25px;
			}
			p {
				margin-bottom: 1rem;
				a {
					color: $black;
				}
			}
		}
	}
}

.has__position {
	position: relative;
	h2 {
		position: relative;
        margin-bottom: -9rem;
        @include media-breakpoint-up(md) {
            position: relative;
            margin-bottom: -8.5rem;
		}
		@include media-breakpoint-up(lg) {
            position: relative;
            margin-bottom: -11.5rem;
        }
	}
}

.module_block_text {
	.giant {
		p {
			@include font-size($giant-font-sizes);
			font-family: $headings-font-family;
			letter-spacing: 6px;
		}
	}
}

.hero__banner-small {
	&.stack__2,
	&.stack__4,
	&.stack__5,
	&.stack__8 {
		min-height: 0;
		padding-bottom: 0;
		padding-top: 10rem;
	}
	&.stack__2,
	&.stack__3,
	&.stack__4,
	&.stack__8 {
		.section__subheading {
			width: 100%;
			display: block;
			margin: 3rem auto 0;
			@include media-breakpoint-up(md) {
				width: 70%;
				margin: 5rem auto 0;
			}
		}
	}
}

.template-fabrication-mesure {
    .stack__2 {
        .section__heading{
            max-width: 100%;
            margin-right: 0;
            @include media-breakpoint-up(lg) {
                max-width: 50rem;
                margin-right: 10rem;
            }
			@include media-breakpoint-up(xl) {
                max-width: 50rem;
                margin-right: 16.5rem;
            }
        }
		.section__description {
			width: 100%;
			@include media-breakpoint-up(xl) {
				width: 100%;
				display: block;
				margin-left: auto;
                margin-right: 0;
                max-width: 50rem;
			}
		}
	}
	.stack__5 {
		.card-deck {
			.card-body {
				h5 {
					font-size: 2.4rem;
				}
			}
		}
	}
}

.template-realisations {
    .hero__banner {
		&.stack__0 {
			.section__subheading {
				p {
                    font-family: $headings-font-family;
                    letter-spacing: 0.6rem;
				}
			}
		}
	}
	.hero__banner-small {
		&.stack__4 {
			padding: 0;
			min-height: 60vh;
		}
		&.stack__2 {
			padding: 10rem 0;
		}
    }
}

.front-page {
	.module_block_text_img {
		&.stack__1,
		&.stack__3 {
			.has__position {
				position: relative;
				img {
					position: relative;
					margin-bottom: 0;
					@include media-breakpoint-up(lg) {
						margin-bottom: 0 !important;
					}
					@include media-breakpoint-up(xl) {
						margin-bottom: -14.5rem !important;
					}
				}
			}
		}
		&.stack__1 {
			.has__position {
				h2 {
					position: relative;
					margin-bottom: -7rem;
					@include media-breakpoint-up(md) {
						margin-bottom: -9.5rem;
					}
					@include media-breakpoint-up(lg) {
						margin-bottom: -16rem;
					}
				}
			}
			.section__description {
                margin-bottom: 4.5rem !important;
				max-width: 100%;
				margin-top: 3rem;
				@include media-breakpoint-up(md) {
                    margin-bottom: 0 !important;
                    max-width: 48rem; 
				}
			}
		}
	}
	.stack__2 {
		padding-top: 5rem;
		@include media-breakpoint-up(xl) {
			padding-top: 15rem;
		}
    }
    .stack__4 {
        .has__position {
            h2 {
                position: relative;
                margin-bottom: -9.5rem;
                @include media-breakpoint-up(md) {
                    margin-bottom: -11.5rem;
				} 
				@include media-breakpoint-up(lg) {
                    margin-bottom: -11.5rem;
                }                
            }
        }
	}
	.stack__5 {
		.has__position {
			h2 {
				position: relative;
                margin-bottom: -8.5rem;
                @include media-breakpoint-up(md) {
					margin-bottom: -11.5rem;
				}
				@include media-breakpoint-up(lg) {
					margin-bottom: -16rem;
                }  
			}
		}
		.list__partenaires {
			padding: 3rem 0;
			@include media-breakpoint-up(lg) {
				padding: 6rem 0;
			}
		 .slick-track {
			 display: flex;
			 justify-content: center;
			 align-content: center;
			 align-items: center;
			 align-self: center;
			 img {
				 width: auto !important;
				 max-width: 100%;
				 display: block;
				 margin:0 auto;
			   }
		 	}
		}
	}
}

.template-conception {
	.stack__0 {
		min-height: 120vh;
		@include media-breakpoint-up(md) {
			min-height: 100vh;
		}
	}
	.module_block_text_img {
		&.stack__3 {
			.has__position {
				position: relative;
				img {
					position: relative;
					margin: 2rem 0 0 0;
					@include media-breakpoint-up(lg) {
						margin: 4rem 0 !important;
					}
					@include media-breakpoint-up(xl) {
						margin: 4rem 0 -20rem 0 !important;
					}
				}
            }
            .section__description {
				max-width: 48rem;
				padding-top: 3rem;
            }
		}
	}
	.stack__2,
	.stack__4 {
		padding-top: 5rem;
		@include media-breakpoint-up(xl) {
			padding-top: 20rem;
		}
	}
	.stack__3:nth-child(even) {
		padding: 2.5rem 0;
		@include media-breakpoint-up(lg) {
			padding: 5rem 0;
		} 
	}
}


.template-choisir-controlerl {
    .stack__2 {
        .section__heading {
            max-width: 100%;
            margin-right: 0 !important;
            @include media-breakpoint-up(lg) {
                max-width: 50rem;
                margin-right: 1.5rem !important;
            }
            @include media-breakpoint-up(xl) {
                max-width: 50rem;
                margin-right: 6.5rem !important;
            }
        }
	}
	.stack__3 {
		padding: 10rem 0;
    }
    .hero__banner-small { 
        &.stack__4 {
            padding: 10rem 0;
            h2 {
                font-size: 4rem;
            }
        }
    }
}

.template-conception,
.template-choisir-controlerl {
	.stack__2 {
        .section__heading{
            max-width: 100%;
            margin-right: 0;
            @include media-breakpoint-up(lg) {
                max-width: 50rem;
                margin-right: 3.5rem;
            }
            @include media-breakpoint-up(xl) {
                max-width: 50rem;
                margin-right: 8rem;
            }
        }
		.section__description {
			width: 100%;
			@include media-breakpoint-up(xl) {
				width: 100%;
				display: block;
				margin-left: auto;
                margin-right: 0;
                max-width: 50rem;
			}
		}
	}
	.stack__4 {
		.section__description {
			width: 100%;
			@include media-breakpoint-up(lg) {
				width: 80%;
				display: block;
				margin-right: auto;
				margin-left: 0;
			}
		}
		.section__description:nth-child(even) {
			width: 100;
			text-align: center;
			margin: 0 auto;
		}
	}
}

.template-champs-dapplication-1,
.template-champs-dapplication-2,
.template-champs-dapplication-3,
.template-champs-dapplication-4,
.template-champs-dapplication-5 {
	.stack__2 {
		.section__heading {
			font-size: 2.5rem !important;
		@include media-breakpoint-up(md) {
			font-size: 4rem;
			}
		}
	}
}

.template-champs-dapplication-1,
.template-champs-dapplication-2,
.template-champs-dapplication-3,
.template-champs-dapplication-4,
.template-champs-dapplication-5 {
	.hero__banner-small { 
            &.stack__3 {
                padding: 10rem 0;
                h2 {
                    font-size: 4rem;
                }
            }
    }
    .hero__banner {
		&.stack__0 {
			.section__subheading {
				p {
                    font-family: $headings-font-family;
                    letter-spacing: 0.6rem;
				}
			}
		}
	}
	.stack__3 {
		padding: 0;
		min-height: 60vh;
    }
    .stack__2 {
        .section__heading {
            
            font-size: 4rem;
			max-width: 100%;
			@include media-breakpoint-up(md) {
				width: 33rem;
			}
			@include media-breakpoint-up(lg) {
				width: 45rem;
			}
			@include media-breakpoint-up(xl) {
				width: 50rem;
			}
        }
        .section__description {
            max-width: 50rem;
            margin-right: 0;
            margin-left: auto;
        }
    }
	.module_champs_application {
		.has__position {
			position: relative;
			h2 {
				position: relative;
				margin-bottom: 0;
				@include media-breakpoint-up(md) {
					margin-bottom: -3rem;
				}
				@include media-breakpoint-up(lg) {
					margin-bottom: -9rem;
				}
			}
		}
	}
}



.block__text {
	&.stack__1 {
		padding-bottom: 5rem;
	}
}

.module_block_text_img {
	&.stack__2,
	&.stack__4 {
		padding-top: 5rem;
	}
}



.template-contact,
.template-soumission,
.politique-de-confidentialite {
    .stack__0 {
        .section__heading-h2 {
            font-size: 1.9rem;
            @include media-breakpoint-up(sm) {
                font-size: 3.5rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 5rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 6rem;
            }
        }
    }
}

.template-soumission {
	.stack__1  {
		padding: 3.5rem 0;
    	@include media-breakpoint-up(md) {
        	padding: 5rem 0; 
    	}
    	@include media-breakpoint-up(lg) {
        	padding: 10rem 0; 
    	} 
	}
}

.template-contact {
    .stack__2 {
        .section__heading-h2 {
            font-size: 1.9rem;
            @include media-breakpoint-up(md) {
                font-size: 3.5rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 4rem;
            }
        }
    }
}


.single-realisations {
	.stack__1 {
		.section__description {
			width: 100%;
			@include media-breakpoint-up(xl) {
				width: 100%;
				display: block;
				margin-left: auto;
                margin-right: 0;
                max-width: 50rem;
			}
		}
	}
	.module_retour_page_realisation {
		padding: 3rem 0;
		@include media-breakpoint-up (lg) {
			padding : 6rem 0;
		}
		.btn {
			width: 100%;
			font-size: 1.5rem;
			@include media-breakpoint-up(md) {
				width: 50%;
			}
			@include media-breakpoint-up(lg) {
				width: 45%;

			}
		}
	}
	.stack__2 {
		.section__description {
			width: 100%;
			@include media-breakpoint-up(xl) {
				width: 100%;
				display: block;
				margin-right: auto;
				margin-left: 0;
				max-width: 50rem;
			}
		}
	}
	.stack__5 {
		padding: 0;
		min-height: 60vh;
	}
	.module_prev_next {
		padding: 2rem 0;
		.btn_next_prev {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-self: center;
			padding: 2rem 0;
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
			.btn_next,
			.btn_previous {
				margin: 2rem 0;
				@include media-breakpoint-up(md) {
					margin: 0 4rem;
				}
			}
		}
	}
}

.template_politique {
	.stack__1 {
		padding: 25px 0;
		@include media-breakpoint-up(md) {
			padding: 50px 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 100px 0;
		}
	}
}

.error404 {
	.block__cta {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		align-self: center;
		margin-top: 2rem;
	}
}

.custom-manufacturing {
	.stack__2 {
		.section__heading {
			max-width: 50rem;
			margin-right: 24.5rem !important;
		}
	}
}

.module_partenaire {
	.list__partenaires {
		.slick-list {
			img {
				width: auto !important;
				max-width: 100%;
				display: block;
				margin: 0 auto;
			}
		}
	}
}