.block__filter {
    margin: 3rem 0;
    .filter_list {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        list-style: none;
        width: 100%;
        padding: 0;
        @include media-breakpoint-up (md) {
            width: 80%;
        }
        .filter__items {
            padding: 0 1rem;
            .filter__link {
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 4rem;
                text-align: center;
                text-decoration: none;
                color: $gray;
                padding: 0;
                @include media-breakpoint-up (md) {
                    font-size: 2rem;
                    padding: 1rem 15px;
                }
            }
        }
    }
}
