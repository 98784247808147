.hero__banner {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .section__heading {
        display: block;
        margin:0 auto 12px;
    }
    p {
        margin: 12px 0 25px;
        width: 100%;
        display: block;
        margin: 0 auto;
        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }
   
    .btn-cta {
        margin-top: 25px;
    }
    .has__bg-red {
        background-color: $red;
    }
}
.hero__banner-small {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    .section__subheading  {
        margin-top: 30px;
    }
}

.module_hero__banner {
    .container-fluid
    {
        position: relative;
            z-index: 2;
    }
    .section__heading-h2
    {
        margin-left: auto !important;
        margin-right: auto !important;
    }
} 

    #video-background 
    {
        position: absolute; 
            z-index: 1; 
        width: 100%; 
        height: 100%; 
    }