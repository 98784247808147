.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-end {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
    }
}

