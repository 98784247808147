/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .gform_wrapper .gform_body {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container, .gform_wrapper .gform_body {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .gform_wrapper .gform_body {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .gform_wrapper .gform_body {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .gform_wrapper .gform_body {
      max-width: 1150px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row, .gform_wrapper .gform_body .gform_fields {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .gform_wrapper .gform_body .gform_fields #field_1_11, .gform_wrapper .gform_body .gform_fields #field_1_12, .gform_wrapper .gform_body .gform_fields #field_1_13, .gform_wrapper .gform_body .gform_fields #field_1_3, .gform_wrapper .gform_body .gform_fields #field_1_4, .gform_wrapper .gform_body .gform_fields #field_1_5, .gform_wrapper .gform_body .gform_fields #field_1_6,
.gform_wrapper .gform_body .gform_fields #field_2_1, .gform_wrapper .gform_body .gform_fields #field_2_2, .gform_wrapper .gform_body .gform_fields #field_2_3, .gform_wrapper .gform_body .gform_fields #field_2_4, .gform_wrapper .gform_body .gform_fields #field_2_5, .gform_wrapper .gform_body .gform_fields #field_2_6,
.gform_wrapper .gform_body .gform_fields #field_3_1, .gform_wrapper .gform_body .gform_fields #field_3_2, .gform_wrapper .gform_body .gform_fields #field_3_3, .gform_wrapper .gform_body .gform_fields #field_3_4, .gform_wrapper .gform_body .gform_fields #field_3_5, .gform_wrapper .gform_body .gform_fields #field_3_6,
.gform_wrapper .gform_body .gform_fields #field_5_11, .gform_wrapper .gform_body .gform_fields #field_5_12, .gform_wrapper .gform_body .gform_fields #field_5_4, .gform_wrapper .gform_body .gform_fields #field_5_6,
.gform_wrapper .gform_body .gform_fields #field_6_1, .gform_wrapper .gform_body .gform_fields #field_6_2, .gform_wrapper .gform_body .gform_fields #field_6_3, .gform_wrapper .gform_body .gform_fields #field_6_4, .gform_wrapper .gform_body .gform_fields #field_6_5, .gform_wrapper .gform_body .gform_fields #field_6_6,
.gform_wrapper .gform_body .gform_fields #field_8_1, .gform_wrapper .gform_body .gform_fields #field_8_2, .gform_wrapper .gform_body .gform_fields #field_8_3, .gform_wrapper .gform_body .gform_fields #field_8_4, .gform_wrapper .gform_body .gform_fields #field_8_5, .gform_wrapper .gform_body .gform_fields #field_8_6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .gform_wrapper .gform_body .gform_fields #field_1_8,
.gform_wrapper .gform_body .gform_fields #field_2_7,
.gform_wrapper .gform_body .gform_fields #field_3_7,
.gform_wrapper .gform_body .gform_fields #field_5_8,
.gform_wrapper .gform_body .gform_fields #field_6_7,
.gform_wrapper .gform_body .gform_fields #field_8_7, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6, .gform_wrapper .gform_body .gform_fields #field_1_11, .gform_wrapper .gform_body .gform_fields #field_1_12, .gform_wrapper .gform_body .gform_fields #field_1_13, .gform_wrapper .gform_body .gform_fields #field_1_3, .gform_wrapper .gform_body .gform_fields #field_1_4, .gform_wrapper .gform_body .gform_fields #field_1_5, .gform_wrapper .gform_body .gform_fields #field_1_6,
  .gform_wrapper .gform_body .gform_fields #field_2_1, .gform_wrapper .gform_body .gform_fields #field_2_2, .gform_wrapper .gform_body .gform_fields #field_2_3, .gform_wrapper .gform_body .gform_fields #field_2_4, .gform_wrapper .gform_body .gform_fields #field_2_5, .gform_wrapper .gform_body .gform_fields #field_2_6,
  .gform_wrapper .gform_body .gform_fields #field_3_1, .gform_wrapper .gform_body .gform_fields #field_3_2, .gform_wrapper .gform_body .gform_fields #field_3_3, .gform_wrapper .gform_body .gform_fields #field_3_4, .gform_wrapper .gform_body .gform_fields #field_3_5, .gform_wrapper .gform_body .gform_fields #field_3_6,
  .gform_wrapper .gform_body .gform_fields #field_5_11, .gform_wrapper .gform_body .gform_fields #field_5_12, .gform_wrapper .gform_body .gform_fields #field_5_4, .gform_wrapper .gform_body .gform_fields #field_5_6,
  .gform_wrapper .gform_body .gform_fields #field_6_1, .gform_wrapper .gform_body .gform_fields #field_6_2, .gform_wrapper .gform_body .gform_fields #field_6_3, .gform_wrapper .gform_body .gform_fields #field_6_4, .gform_wrapper .gform_body .gform_fields #field_6_5, .gform_wrapper .gform_body .gform_fields #field_6_6,
  .gform_wrapper .gform_body .gform_fields #field_8_1, .gform_wrapper .gform_body .gform_fields #field_8_2, .gform_wrapper .gform_body .gform_fields #field_8_3, .gform_wrapper .gform_body .gform_fields #field_8_4, .gform_wrapper .gform_body .gform_fields #field_8_5, .gform_wrapper .gform_body .gform_fields #field_8_6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12, .gform_wrapper .gform_body .gform_fields #field_1_8,
  .gform_wrapper .gform_body .gform_fields #field_2_7,
  .gform_wrapper .gform_body .gform_fields #field_3_7,
  .gform_wrapper .gform_body .gform_fields #field_5_8,
  .gform_wrapper .gform_body .gform_fields #field_6_7,
  .gform_wrapper .gform_body .gform_fields #field_8_7 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #495057;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .form-control-plaintext.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .form-control-plaintext.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #868e96; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .invalid-feedback,
  .was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
  .form-control.is-valid ~ .invalid-tooltip, .was-validated
  .custom-select:valid ~ .invalid-feedback,
  .was-validated
  .custom-select:valid ~ .invalid-tooltip,
  .custom-select.is-valid ~ .invalid-feedback,
  .custom-select.is-valid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  -js-display: flex;
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      -js-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      -js-display: flex;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      -js-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -js-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.show > a {
  outline: 0; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto;
    margin-bottom: 0; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  -js-display: flex;
  display: flex;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  -js-display: flex;
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
      z-index: 3; }
  .input-group-btn:not(:last-child) > .btn,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -1px; }
  .input-group-btn:not(:first-child) > .btn,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: -1px; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff; }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #e9ecef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #868e96; }

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  -js-display: flex;
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0 0.25rem 0.25rem 0; }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
  .nav-pills .nav-link.active,
  .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container, .gform_wrapper .navbar > .gform_body,
  .navbar > .container-fluid {
    -js-display: flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  -js-display: flex;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575px) {
  .navbar-expand-sm > .container, .gform_wrapper .navbar-expand-sm > .gform_body,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container, .gform_wrapper .navbar-expand-sm > .gform_body,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      -js-display: flex;
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767px) {
  .navbar-expand-md > .container, .gform_wrapper .navbar-expand-md > .gform_body,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container, .gform_wrapper .navbar-expand-md > .gform_body,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      -js-display: flex;
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-expand-lg > .container, .gform_wrapper .navbar-expand-lg > .gform_body,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container, .gform_wrapper .navbar-expand-lg > .gform_body,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      -js-display: flex;
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199px) {
  .navbar-expand-xl > .container, .gform_wrapper .navbar-expand-xl > .gform_body,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container, .gform_wrapper .navbar-expand-xl > .gform_body,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      -js-display: flex;
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .gform_wrapper .navbar-expand > .gform_body,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container, .gform_wrapper .navbar-expand > .gform_body,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    -js-display: flex;
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.card {
  position: relative;
  -js-display: flex;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

@media (min-width: 576px) {
  .card-deck {
    -js-display: flex;
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -js-display: flex;
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px; } }

@media (min-width: 576px) {
  .card-group {
    -js-display: flex;
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0%; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }
  .breadcrumb::after {
    display: block;
    clear: both;
    content: ""; }

.breadcrumb-item {
  float: left; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #868e96;
    content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #868e96; }

.pagination {
  -js-display: flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #868e96; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #111;
  background-color: #ffc107; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #111;
  background-color: #f8f9fa; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2; }
  .alert-secondary hr {
    border-top-color: #cfd2d6; }
  .alert-secondary .alert-link {
    color: #2e3133; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  -js-display: flex;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  -js-display: flex;
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  -js-display: flex;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #004085;
    background-color: #9fcdff; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #464a4e;
    background-color: #cfd2d6; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #818182; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  -js-display: flex;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -3px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -3px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 11px; }
  .popover .arrow::after {
    content: "";
    border-width: 11px; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 10px; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -11px;
      margin-left: -6px;
      border-top-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: -10px;
      margin-left: -6px;
      border-top-color: #fff; }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 10px; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -8px;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -11px;
      border-right-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: -10px;
      border-right-color: #fff; }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 10px; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -7px;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: -10px;
      border-bottom-color: #fff; }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 10px; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -8px;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -11px;
      border-left-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: -10px;
      border-left-color: #fff; }

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 9px 14px;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  -js-display: flex;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  -js-display: flex;
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    -js-display: flex;
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    -js-display: flex;
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    -js-display: flex;
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    -js-display: flex;
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*Pour plus d'infos: https://github.com/xtianmiller/emergence.js

Les configurations sont dans client.js
*/
/*///////////// STYLES //////////////////////////
*/
.emerge[data-emergence=hidden] {
  opacity: 0; }

.emerge[data-emergence=visible] {
  opacity: 1; }

.emerge-top[data-emergence=hidden] {
  opacity: 0;
  transform: translateY(-50px); }

.emerge-top[data-emergence=visible] {
  opacity: 1;
  transform: translateY(0px); }

.emerge-right[data-emergence=hidden] {
  opacity: 0;
  transform: translateX(50px); }

.emerge-right[data-emergence=visible] {
  opacity: 1;
  transform: translateX(0px); }

.emerge-bottom[data-emergence=hidden] {
  opacity: 0;
  transform: translateY(50px); }

.emerge-bottom[data-emergence=visible] {
  opacity: 1;
  transform: translateY(0px); }

.emerge-left[data-emergence=hidden] {
  opacity: 0;
  transform: translateX(-50px); }

.emerge-left[data-emergence=visible] {
  opacity: 1;
  transform: translateX(0px); }

/*/////////////// SPEED //////////////////////////
*/
.emerge-speed-1 {
  transition: all 1s ease-out; }

.emerge-speed-1pt5 {
  transition: all 1.5s ease-out; }

.emerge-speed-2 {
  transition: all 2s ease-out; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  line-height: 1.5rem; }

.section__projects {
  background-color: #080708; }

.has__bg-beige {
  background-color: #F5F5F5; }

.has__bg-black {
  background-color: #080708; }

.has__bg-white {
  background-color: #fff; }

.has__bg-red {
  background-color: #D0021B; }

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none; }

.outer-menu {
  position: fixed;
  /*top: 6.2rem;
    left: 0;
    z-index: 1;*/
  z-index: 999999;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 8rem;
  /*height: 8rem;*/
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .outer-menu {
      width: 7rem;
      height: 7rem; } }
  @media (min-width: 992px) {
    .outer-menu {
      width: 8.5rem;
      height: 8.5rem;
      top: 8.2rem;
      left: 0;
      z-index: 1; } }
  .outer-menu .checkbox-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    opacity: 0;
    transform: translate(-50%, -50%);
    color: #080708;
    background-color: #080708; }
    .outer-menu .checkbox-toggle:checked + .hamburger div {
      transform: rotate(135deg); }
      .outer-menu .checkbox-toggle:checked + .hamburger div:before, .outer-menu .checkbox-toggle:checked + .hamburger div:after {
        top: 0;
        transform: rotate(90deg); }
      .outer-menu .checkbox-toggle:checked + .hamburger div:after {
        opacity: 0; }
    .outer-menu .checkbox-toggle:checked ~ .menu {
      pointer-events: auto;
      visibility: visible;
      width: 100%;
      transition-duration: .2s; }
      .outer-menu .checkbox-toggle:checked ~ .menu div {
        transform: scale(1);
        transition-duration: .3s;
        opacity: 1; }
        .outer-menu .checkbox-toggle:checked ~ .menu div div {
          opacity: 1;
          transition: opacity 0.4s ease 0.4s; }
    .outer-menu .checkbox-toggle:checked:hover + .hamburger > div {
      transform: rotate(225deg); }
  .outer-menu .hamburger {
    z-index: 1;
    width: 6rem;
    height: 6rem;
    padding: .5em 1em;
    border-radius: 0 .12em .12em 0;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    .outer-menu .hamburger div {
      position: relative;
      flex: none;
      width: 100%;
      height: .2rem;
      background-color: #080708;
      transition: all 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center; }
      .outer-menu .hamburger div:before, .outer-menu .hamburger div:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -1rem;
        left: 0;
        width: 100%;
        height: .2rem;
        background-color: #080708;
        transition: all 0.4s ease; }
      .outer-menu .hamburger div:after {
        top: 1rem; }
  .outer-menu .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D0021B;
    transition: 0.2s; }
    .outer-menu .menu .overlay {
      color: #fff;
      text-align: left;
      width: 100%;
      position: relative;
      top: 5rem;
      transition: 0.3s;
      opacity: 0;
      backface-visibility: hidden;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      flex-direction: column; }
      @media (min-width: 768px) {
        .outer-menu .menu .overlay {
          top: 3rem;
          width: 70%; } }
      .outer-menu .menu .overlay .menu__list {
        list-style: none;
        display: block; }
        .outer-menu .menu .overlay .menu__list li {
          padding: 0;
          margin: 1rem 0;
          padding: 1rem 0;
          display: block; }
          @media (min-width: 768px) {
            .outer-menu .menu .overlay .menu__list li {
              padding: 1.5rem 0; } }
          @media (min-width: 992px) {
            .outer-menu .menu .overlay .menu__list li {
              padding: 2rem 0; } }
          .outer-menu .menu .overlay .menu__list li a {
            position: relative;
            display: inline;
            cursor: pointer;
            transition: color 0.4s ease;
            font-size: 2rem;
            text-align: left; }
            @media (min-width: 768px) {
              .outer-menu .menu .overlay .menu__list li a {
                font-size: 3.5rem; } }
            @media (min-width: 992px) {
              .outer-menu .menu .overlay .menu__list li a {
                font-size: 5rem; } }
            .outer-menu .menu .overlay .menu__list li a:hover {
              color: #e6e6e6; }
              .outer-menu .menu .overlay .menu__list li a:hover:after {
                width: 100%; }
            .outer-menu .menu .overlay .menu__list li a:after {
              content: '';
              position: absolute;
              z-index: 1;
              bottom: -.15em;
              left: 0;
              width: 3.5rem;
              height: .2rem;
              background-color: #e6e6e6;
              transition: width 0.4s ease; }
      .outer-menu .menu .overlay .block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1rem 0;
        width: 90%; }
        @media (min-width: 768px) {
          .outer-menu .menu .overlay .block {
            flex-direction: row;
            justify-content: space-between; } }
        .outer-menu .menu .overlay .block .block__lang {
          display: inline-flex; }
          @media (min-width: 992px) {
            .outer-menu .menu .overlay .block .block__lang {
              display: block; } }
        .outer-menu .menu .overlay .block .block__lang .lang__list,
        .outer-menu .menu .overlay .block .block__lang .contact__list,
        .outer-menu .menu .overlay .block .block__lang .social__list,
        .outer-menu .menu .overlay .block .block__contact .lang__list,
        .outer-menu .menu .overlay .block .block__contact .contact__list,
        .outer-menu .menu .overlay .block .block__contact .social__list,
        .outer-menu .menu .overlay .block .block__social .lang__list,
        .outer-menu .menu .overlay .block .block__social .contact__list,
        .outer-menu .menu .overlay .block .block__social .social__list {
          display: inline-flex;
          list-style: none; }
          .outer-menu .menu .overlay .block .block__lang .lang__list li,
          .outer-menu .menu .overlay .block .block__lang .contact__list li,
          .outer-menu .menu .overlay .block .block__lang .social__list li,
          .outer-menu .menu .overlay .block .block__contact .lang__list li,
          .outer-menu .menu .overlay .block .block__contact .contact__list li,
          .outer-menu .menu .overlay .block .block__contact .social__list li,
          .outer-menu .menu .overlay .block .block__social .lang__list li,
          .outer-menu .menu .overlay .block .block__social .contact__list li,
          .outer-menu .menu .overlay .block .block__social .social__list li {
            margin: 0 2rem 0 0;
            line-height: 0.1rem; }
            .outer-menu .menu .overlay .block .block__lang .lang__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__lang .contact__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__lang .social__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__contact .lang__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__contact .contact__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__contact .social__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__social .lang__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__social .contact__list li .svg-inline--fa,
            .outer-menu .menu .overlay .block .block__social .social__list li .svg-inline--fa {
              font-size: 2.4rem; }
        .outer-menu .menu .overlay .block .block__lang .contact__list,
        .outer-menu .menu .overlay .block .block__contact .contact__list,
        .outer-menu .menu .overlay .block .block__social .contact__list {
          display: inline-flex; }
          @media (min-width: 992px) {
            .outer-menu .menu .overlay .block .block__lang .contact__list,
            .outer-menu .menu .overlay .block .block__contact .contact__list,
            .outer-menu .menu .overlay .block .block__social .contact__list {
              display: none; } }

.btn-cta {
  font-size: 1.6rem;
  letter-spacing: 0.3rem;
  line-height: 1.8rem;
  border-radius: 0;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  text-transform: uppercase;
  white-space: normal;
  max-width: 100%;
  outline: none; }
  @media (min-width: 992px) {
    .btn-cta {
      letter-spacing: 0.5rem; } }
  .btn-cta:focus, .btn-cta:active, .btn-cta:visited {
    outline: none; }
  .btn-cta.has__bg-red {
    background-color: #D0021B;
    padding: 2rem 0;
    width: 100%;
    text-transform: uppercase; }
    .btn-cta.has__bg-red:after {
      display: none; }
  .btn-cta.has__color-white {
    color: #fff; }
  .btn-cta.has__color-black {
    color: #080708; }
  .btn-cta:after {
    content: "";
    position: absolute;
    bottom: -1rem;
    z-index: 1;
    left: 0;
    width: 2.8rem;
    height: 0.3rem;
    background-color: #D0021B;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease; }
  .btn-cta:hover::after {
    width: 100%;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease; }

.btn__cta-big {
  background-color: #D0021B;
  font-size: 3.75rem;
  text-align: center;
  color: white;
  padding: 0 2rem;
  border-radius: 0; }
  .btn__cta-big:focus, .btn__cta-big:active, .btn__cta-big:visited {
    outline: none; }

button {
  background-color: transparent;
  border-radius: 0; }
  button.btn-cta.has__color-white {
    color: #fff; }
  button.btn-cta.has__color-black {
    color: #080708; }

.card-deck {
  margin: 5rem 0;
  justify-content: flex-start; }
  .card-deck .has__padding {
    padding-bottom: 3rem; }
    @media (min-width: 768px) {
      .card-deck .has__padding {
        padding-bottom: 4rem; } }
  .card-deck .card {
    border: none;
    background-color: #fff;
    margin-bottom: 3rem;
    border-radius: 0; }
    .card-deck .card.has__full-height {
      height: 100%; }
    .card-deck .card .card-body {
      padding: 4rem 3rem; }
      .card-deck .card .card-body h5 {
        font-size: 2rem;
        letter-spacing: 0.2rem;
        line-height: 3rem; }
        @media (min-width: 1200px) {
          .card-deck .card .card-body h5 {
            font-size: 2.3rem; } }
      .card-deck .card .card-body .card-lead,
      .card-deck .card .card-body .card-description {
        color: #9B9B9B;
        font-size: 1.4rem !important;
        letter-spacing: 0.1rem !important;
        line-height: 1.5rem !important; }
      .card-deck .card .card-body .card-text {
        font-size: 1.4rem !important;
        letter-spacing: 0.1rem !important;
        line-height: 1.5rem !important; }
    .card-deck .card .card-bottom {
      padding: 5rem; }
      .card-deck .card .card-bottom .btn {
        padding: 0;
        text-align: left; }

.card {
  border: none; }
  .card .card-wrapper {
    margin-bottom: 1.5rem; }
    @media (min-width: 992px) {
      .card .card-wrapper {
        margin-bottom: 4rem; } }
    .card .card-wrapper .card-img-top {
      margin-bottom: 4rem;
      object-fit: cover; }
    .card .card-wrapper .card-icon {
      width: 8rem;
      height: 8rem;
      display: block;
      margin: 0 auto; }
      .card .card-wrapper .card-icon .icon {
        max-width: 100%;
        object-fit: cover; }
    .card .card-wrapper .card-box .section__heading-h4 {
      margin: 2rem auto 3rem;
      text-align: center; }
    .card .card-wrapper .card-box .card-description p {
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      line-height: 2.1rem;
      text-align: center; }
    .card .card-wrapper .card-box .btn {
      color: #080708;
      padding: 0;
      text-align: left; }

.icon-box .icon-box-icon {
  width: 8rem;
  height: 8rem;
  display: block;
  margin: 0 auto; }
  .icon-box .icon-box-icon img {
    max-width: 100%;
    object-fit: cover; }

.icon-box .icon-box-title h4 {
  margin: 2rem auto 3rem;
  text-align: center; }

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  display: none; }

.carousel .carousel-indicators {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  cursor: pointer; }
  .carousel .carousel-indicators li {
    background-color: #D0021B;
    height: 0.9rem;
    width: 0.9rem; }
    .carousel .carousel-indicators li.active {
      background-color: #080708; }

.carousel .carousel-inner .carousel-item {
  text-align: right;
  padding: 5rem 0; }
  .carousel .carousel-inner .carousel-item .carousel-description {
    font-size: 2rem;
    font-family: "work sans";
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 4.5rem;
    color: #080708; }
    @media (min-width: 768px) {
      .carousel .carousel-inner .carousel-item .carousel-description {
        font-size: 3.3rem; } }
  .carousel .carousel-inner .carousel-item .carousel-name {
    text-align: right;
    font-size: 1.8rem;
    letter-spacing: 2.4px;
    line-height: 3rem; }

/* Slider */
.slick-slide {
  margin: 0 2rem; }

.slick-slide img {
  width: 100%; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 0.1rem; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.block__filter {
  margin: 3rem 0; }
  .block__filter .filter_list {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    list-style: none;
    width: 100%;
    padding: 0; }
    @media (min-width: 768px) {
      .block__filter .filter_list {
        width: 80%; } }
    .block__filter .filter_list .filter__items {
      padding: 0 1rem; }
      .block__filter .filter_list .filter__items .filter__link {
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 4rem;
        text-align: center;
        text-decoration: none;
        color: #9B9B9B;
        padding: 0; }
        @media (min-width: 768px) {
          .block__filter .filter_list .filter__items .filter__link {
            font-size: 2rem;
            padding: 1rem 15px; } }

.gform_wrapper .validation_error {
  color: #D0021B;
  padding: 5rem 0; }

.gform_wrapper img.gform_ajax_spinner {
  display: none !important; }

.gform_wrapper .gform_body {
  padding: 3rem 0 0; }
  .gform_wrapper .gform_body .gform_fields {
    padding: 0;
    margin: 0;
    list-style: none; }
    .gform_wrapper .gform_body .gform_fields #field_1_11, .gform_wrapper .gform_body .gform_fields #field_1_12, .gform_wrapper .gform_body .gform_fields #field_1_13, .gform_wrapper .gform_body .gform_fields #field_1_3, .gform_wrapper .gform_body .gform_fields #field_1_4, .gform_wrapper .gform_body .gform_fields #field_1_5, .gform_wrapper .gform_body .gform_fields #field_1_6,
    .gform_wrapper .gform_body .gform_fields #field_2_1, .gform_wrapper .gform_body .gform_fields #field_2_2, .gform_wrapper .gform_body .gform_fields #field_2_3, .gform_wrapper .gform_body .gform_fields #field_2_4, .gform_wrapper .gform_body .gform_fields #field_2_5, .gform_wrapper .gform_body .gform_fields #field_2_6,
    .gform_wrapper .gform_body .gform_fields #field_3_1, .gform_wrapper .gform_body .gform_fields #field_3_2, .gform_wrapper .gform_body .gform_fields #field_3_3, .gform_wrapper .gform_body .gform_fields #field_3_4, .gform_wrapper .gform_body .gform_fields #field_3_5, .gform_wrapper .gform_body .gform_fields #field_3_6,
    .gform_wrapper .gform_body .gform_fields #field_5_11, .gform_wrapper .gform_body .gform_fields #field_5_12, .gform_wrapper .gform_body .gform_fields #field_5_4, .gform_wrapper .gform_body .gform_fields #field_5_6,
    .gform_wrapper .gform_body .gform_fields #field_6_1, .gform_wrapper .gform_body .gform_fields #field_6_2, .gform_wrapper .gform_body .gform_fields #field_6_3, .gform_wrapper .gform_body .gform_fields #field_6_4, .gform_wrapper .gform_body .gform_fields #field_6_5, .gform_wrapper .gform_body .gform_fields #field_6_6,
    .gform_wrapper .gform_body .gform_fields #field_8_1, .gform_wrapper .gform_body .gform_fields #field_8_2, .gform_wrapper .gform_body .gform_fields #field_8_3, .gform_wrapper .gform_body .gform_fields #field_8_4, .gform_wrapper .gform_body .gform_fields #field_8_5, .gform_wrapper .gform_body .gform_fields #field_8_6 {
      padding: 1rem; }
      .gform_wrapper .gform_body .gform_fields #field_1_11 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_1_12 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_1_13 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_1_3 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_1_4 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_1_5 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_1_6 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_2_1 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_2_2 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_2_3 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_2_4 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_2_5 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_2_6 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_3_1 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_3_2 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_3_3 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_3_4 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_3_5 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_3_6 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_5_11 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_5_12 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_5_4 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_5_6 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_6_1 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_6_2 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_6_3 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_6_4 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_6_5 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_6_6 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_8_1 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_8_2 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_8_3 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_8_4 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_8_5 .gfield_label, .gform_wrapper .gform_body .gform_fields #field_8_6 .gfield_label {
        display: none; }
      .gform_wrapper .gform_body .gform_fields #field_1_11 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_1_12 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_1_13 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_1_3 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_1_4 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_1_5 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_1_6 .ginput_container input,
      .gform_wrapper .gform_body .gform_fields #field_2_1 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_2_2 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_2_3 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_2_4 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_2_5 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_2_6 .ginput_container input,
      .gform_wrapper .gform_body .gform_fields #field_3_1 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_3_2 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_3_3 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_3_4 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_3_5 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_3_6 .ginput_container input,
      .gform_wrapper .gform_body .gform_fields #field_5_11 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_5_12 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_5_4 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_5_6 .ginput_container input,
      .gform_wrapper .gform_body .gform_fields #field_6_1 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_6_2 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_6_3 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_6_4 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_6_5 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_6_6 .ginput_container input,
      .gform_wrapper .gform_body .gform_fields #field_8_1 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_8_2 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_8_3 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_8_4 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_8_5 .ginput_container input, .gform_wrapper .gform_body .gform_fields #field_8_6 .ginput_container input {
        width: 100%;
        padding: 1rem; }
      .gform_wrapper .gform_body .gform_fields #field_1_11 .validation_message, .gform_wrapper .gform_body .gform_fields #field_1_12 .validation_message, .gform_wrapper .gform_body .gform_fields #field_1_13 .validation_message, .gform_wrapper .gform_body .gform_fields #field_1_3 .validation_message, .gform_wrapper .gform_body .gform_fields #field_1_4 .validation_message, .gform_wrapper .gform_body .gform_fields #field_1_5 .validation_message, .gform_wrapper .gform_body .gform_fields #field_1_6 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_2_1 .validation_message, .gform_wrapper .gform_body .gform_fields #field_2_2 .validation_message, .gform_wrapper .gform_body .gform_fields #field_2_3 .validation_message, .gform_wrapper .gform_body .gform_fields #field_2_4 .validation_message, .gform_wrapper .gform_body .gform_fields #field_2_5 .validation_message, .gform_wrapper .gform_body .gform_fields #field_2_6 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_3_1 .validation_message, .gform_wrapper .gform_body .gform_fields #field_3_2 .validation_message, .gform_wrapper .gform_body .gform_fields #field_3_3 .validation_message, .gform_wrapper .gform_body .gform_fields #field_3_4 .validation_message, .gform_wrapper .gform_body .gform_fields #field_3_5 .validation_message, .gform_wrapper .gform_body .gform_fields #field_3_6 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_5_11 .validation_message, .gform_wrapper .gform_body .gform_fields #field_5_12 .validation_message, .gform_wrapper .gform_body .gform_fields #field_5_4 .validation_message, .gform_wrapper .gform_body .gform_fields #field_5_6 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_6_1 .validation_message, .gform_wrapper .gform_body .gform_fields #field_6_2 .validation_message, .gform_wrapper .gform_body .gform_fields #field_6_3 .validation_message, .gform_wrapper .gform_body .gform_fields #field_6_4 .validation_message, .gform_wrapper .gform_body .gform_fields #field_6_5 .validation_message, .gform_wrapper .gform_body .gform_fields #field_6_6 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_8_1 .validation_message, .gform_wrapper .gform_body .gform_fields #field_8_2 .validation_message, .gform_wrapper .gform_body .gform_fields #field_8_3 .validation_message, .gform_wrapper .gform_body .gform_fields #field_8_4 .validation_message, .gform_wrapper .gform_body .gform_fields #field_8_5 .validation_message, .gform_wrapper .gform_body .gform_fields #field_8_6 .validation_message {
        color: #D0021B !important; }
    .gform_wrapper .gform_body .gform_fields #field_1_8,
    .gform_wrapper .gform_body .gform_fields #field_2_7,
    .gform_wrapper .gform_body .gform_fields #field_3_7,
    .gform_wrapper .gform_body .gform_fields #field_5_8,
    .gform_wrapper .gform_body .gform_fields #field_6_7,
    .gform_wrapper .gform_body .gform_fields #field_8_7 {
      padding: 1rem; }
      .gform_wrapper .gform_body .gform_fields #field_1_8 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_2_7 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_3_7 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_5_8 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_6_7 .gfield_label,
      .gform_wrapper .gform_body .gform_fields #field_8_7 .gfield_label {
        display: none; }
      .gform_wrapper .gform_body .gform_fields #field_1_8 .ginput_container textarea,
      .gform_wrapper .gform_body .gform_fields #field_2_7 .ginput_container textarea,
      .gform_wrapper .gform_body .gform_fields #field_3_7 .ginput_container textarea,
      .gform_wrapper .gform_body .gform_fields #field_5_8 .ginput_container textarea,
      .gform_wrapper .gform_body .gform_fields #field_6_7 .ginput_container textarea,
      .gform_wrapper .gform_body .gform_fields #field_8_7 .ginput_container textarea {
        width: 100%;
        padding: 1rem; }
      .gform_wrapper .gform_body .gform_fields #field_1_8 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_2_7 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_3_7 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_5_8 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_6_7 .validation_message,
      .gform_wrapper .gform_body .gform_fields #field_8_7 .validation_message {
        color: #D0021B !important; }
    .gform_wrapper .gform_body .gform_fields #field_3_8 .ginput_container input#input_3_8,
    .gform_wrapper .gform_body .gform_fields #field_3_8 .ginput_container input#input_6_8,
    .gform_wrapper .gform_body .gform_fields #field_3_8 .ginput_container input#input_2_8,
    .gform_wrapper .gform_body .gform_fields #field_3_8 .ginput_container input#input_8_8,
    .gform_wrapper .gform_body .gform_fields #field_6_8 .ginput_container input#input_3_8,
    .gform_wrapper .gform_body .gform_fields #field_6_8 .ginput_container input#input_6_8,
    .gform_wrapper .gform_body .gform_fields #field_6_8 .ginput_container input#input_2_8,
    .gform_wrapper .gform_body .gform_fields #field_6_8 .ginput_container input#input_8_8,
    .gform_wrapper .gform_body .gform_fields #field_2_8 .ginput_container input#input_3_8,
    .gform_wrapper .gform_body .gform_fields #field_2_8 .ginput_container input#input_6_8,
    .gform_wrapper .gform_body .gform_fields #field_2_8 .ginput_container input#input_2_8,
    .gform_wrapper .gform_body .gform_fields #field_2_8 .ginput_container input#input_8_8,
    .gform_wrapper .gform_body .gform_fields #field_8_8 .ginput_container input#input_3_8,
    .gform_wrapper .gform_body .gform_fields #field_8_8 .ginput_container input#input_6_8,
    .gform_wrapper .gform_body .gform_fields #field_8_8 .ginput_container input#input_2_8,
    .gform_wrapper .gform_body .gform_fields #field_8_8 .ginput_container input#input_8_8 {
      color: white;
      margin-bottom: 2rem; }
    .gform_wrapper .gform_body .gform_fields .ginput_container_fileupload {
      padding: 1rem; }

.gform_wrapper .gform_footer {
  padding: 10px; }
  .gform_wrapper .gform_footer input[type=submit] {
    font-size: 1.6rem;
    letter-spacing: 0.3rem;
    line-height: 1.8rem;
    border-radius: 0;
    position: relative;
    text-transform: uppercase;
    white-space: normal;
    max-width: 100%;
    cursor: pointer;
    outline: none;
    border: none;
    color: white;
    background-color: #D0021B;
    padding: 1rem; }
    @media (min-width: 992px) {
      .gform_wrapper .gform_footer input[type=submit] {
        letter-spacing: 0.5rem; } }
    .gform_wrapper .gform_footer input[type=submit]:focus, .gform_wrapper .gform_footer input[type=submit]:active, .gform_wrapper .gform_footer input[type=submit]:visited {
      outline: none; }
    .gform_wrapper .gform_footer input[type=submit]:after {
      content: "";
      position: absolute;
      bottom: -1rem;
      z-index: 1;
      left: 0;
      width: 2.8rem !important;
      height: 0.3rem;
      background-color: #D0021B !important;
      -webkit-transition: width 0.3s ease !important;
      transition: width 0.3s ease !important; }
    .gform_wrapper .gform_footer input[type=submit]:hover::after {
      width: 100% !important;
      -webkit-transition: width 0.3s ease !important;
      transition: width 0.3s ease !important; }

.gform_confirmation_wrapper {
  padding: 10rem 0 !important; }
  .gform_confirmation_wrapper .gform_confirmation_message {
    color: #fff !important; }

.hero__banner {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .hero__banner .section__heading, .hero__banner .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .hero__banner span,
  .hero__banner .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .hero__banner span {
    display: block;
    margin: 0 auto 12px; }
  .hero__banner p {
    margin: 12px 0 25px;
    width: 100%;
    display: block;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .hero__banner p {
        width: 80%; } }
  .hero__banner .btn-cta {
    margin-top: 25px; }
  .hero__banner .has__bg-red {
    background-color: #D0021B; }

.hero__banner-small {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain; }
  .hero__banner-small .section__subheading {
    margin-top: 30px; }

.module_hero__banner .container-fluid {
  position: relative;
  z-index: 2; }

.module_hero__banner .section__heading-h2 {
  margin-left: auto !important;
  margin-right: auto !important; }

#video-background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%; }

.section__map .acf-map {
  height: 40rem; }
  @media (min-width: 768px) {
    .section__map .acf-map {
      height: 60rem; } }

.section__map .block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .section__map .block {
      flex-direction: row;
      align-items: flex-start; } }
  .section__map .block .block__contact {
    margin: 5%; }

.block__flex {
  display: flex;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 5rem 1.5rem; }
  @media (min-width: 992px) {
    .block__flex {
      flex-direction: row;
      padding: 5rem 1.5rem;
      align-self: center; } }
  .block__flex .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: center;
    width: 100%; }
    @media (min-width: 768px) {
      .block__flex .block {
        width: 50%; } }
    @media (min-width: 992px) {
      .block__flex .block {
        width: 35%; } }
    .block__flex .block .block__contact {
      display: block;
      margin: 5% auto;
      text-align: center; }
      .block__flex .block .block__contact .texte p, .block__flex .block .block__contact .texte a {
        font-size: 1.6rem !important;
        letter-spacing: 0.1rem !important;
        line-height: 2.1rem !important;
        text-align: center !important; }
    .block__flex .block .block__social {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: center;
      padding-top: 2rem; }
      @media (min-width: 992px) {
        .block__flex .block .block__social {
          padding-top: 4rem; } }
      .block__flex .block .block__social .social__list {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        list-style: none;
        padding: 0; }
        .block__flex .block .block__social .social__list .social__item {
          padding: 0 2rem; }
          .block__flex .block .block__social .social__list .social__item .social__link {
            color: #D0021B; }
  .block__flex .block:nth-child(even) .block__contact:nth-child(odd) {
    margin: 5% auto; }
    @media (min-width: 992px) {
      .block__flex .block:nth-child(even) .block__contact:nth-child(odd) {
        margin-bottom: 7rem; } }

.navbar {
  background-color: #080708;
  padding: 0; }
  .navbar .container-fluid {
    padding-right: 0; }
    .navbar .container-fluid .navbar-brand {
      display: block;
      margin: 5px auto !important; }
      @media (min-width: 768px) {
        .navbar .container-fluid .navbar-brand {
          margin-left: 3.2rem !important; } }
  .navbar .navbar-nav .nav-item a {
    text-transform: uppercase; }
  .navbar .navbar-nav li {
    color: #fff;
    border: 1px solid #fff;
    border-top: 0px;
    border-bottom: 0px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    align-items: center; }
    .navbar .navbar-nav li a {
      display: flex;
      justify-content: center;
      align-content: center;
      align-self: center;
      align-items: center;
      height: 8.2rem;
      padding: 3rem; }
    .navbar .navbar-nav li:nth-child(1) .nav-link, .navbar .navbar-nav li:nth-child(2) .nav-link {
      font-size: 1.5rem; }
      .navbar .navbar-nav li:nth-child(1) .nav-link:hover, .navbar .navbar-nav li:nth-child(2) .nav-link:hover {
        background-color: #D0021B;
        transition: .3s all ease; }
    .navbar .navbar-nav li:nth-child(3):hover {
      background-color: #D0021B;
      transition: .3s all ease; }
    .navbar .navbar-nav li:nth-child(4) {
      background-color: #fff;
      color: #080708;
      border: 0px;
      transition: .3s all ease; }
      .navbar .navbar-nav li:nth-child(4):hover {
        background-color: #D0021B;
        color: #fff;
        transition: .3s all ease;
        border-right: 1px solid #fff; }
    .navbar .navbar-nav li:nth-child(5) {
      background-color: #D0021B;
      color: #fff;
      border: 0px;
      transition: .3s all ease; }
      .navbar .navbar-nav li:nth-child(5):hover {
        background-color: #fff !important;
        color: #080708;
        transition: .3s all ease;
        border-left: 1px solid #D0021B; }
    .navbar .navbar-nav li .nav-link {
      font-size: 1.2rem;
      letter-spacing: 0.4rem;
      line-height: 1.5rem;
      text-align: center;
      padding: 3rem; }

.mouse-container {
  display: none; }
  @media (min-width: 768px) {
    .mouse-container {
      position: absolute;
      display: block;
      margin: 2rem auto;
      bottom: 2rem;
      left: 49%;
      height: 5rem;
      transform: translateX(-50%); } }
  .mouse-container .mouse {
    position: relative;
    margin: 0 auto;
    display: block;
    width: 3rem;
    height: 5rem;
    border: solid 2px #fff;
    border-radius: 25px;
    -webkit-animation: scroll-mouse 1.5s;
    animation: scroll-mouse 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease; }
    .mouse-container .mouse .scroll-down {
      display: block;
      width: 2px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
      margin: 15% auto auto auto;
      -webkit-animation: scroll-inner 1.5s;
      animation: scroll-inner 1.5s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
      animation-timing-function: ease; }

@-webkit-keyframes scroll-inner {
  from {
    margin-top: 15%; }
  to {
    margin-top: 50%; } }

@keyframes scroll-inner {
  from {
    margin-top: 15%; }
  to {
    margin-top: 50%; } }

@-webkit-keyframes scroll-mouse {
  from {
    margin-top: 0; }
  to {
    margin-top: 15px; } }

@keyframes scroll-mouse {
  from {
    margin-top: 0; }
  to {
    margin-top: 15px; } }

.scrolltop {
  display: none;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 55px;
  right: 0; }
  .scrolltop .scroll {
    position: absolute;
    right: 0;
    bottom: -45px;
    padding: 2rem;
    text-align: center;
    margin: 0 0 0 0;
    cursor: pointer;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transform: translate(-50%, -50%); }
    .scrolltop .scroll img {
      width: 5rem;
      height: auto;
      max-width: 100%;
      object-fit: cover;
      display: none; }
      @media (min-width: 768px) {
        .scrolltop .scroll img {
          display: block; } }

.section__heading-span {
  color: #fff;
  text-align: center;
  display: block; }

.section__heading-h2 {
  line-height: 5rem;
  text-align: left;
  color: #fff; }
  .section__heading-h2.has__bg-red {
    background-color: #D0021B;
    color: #fff;
    padding: 2rem;
    display: table;
    text-align: left; }
    .section__heading-h2.has__bg-red:nth-child(1) {
      margin-bottom: 2rem; }
    .section__heading-h2.has__bg-red:nth-child(2) {
      margin-bottom: 2rem; }
    .section__heading-h2.has__bg-red:nth-child(3) {
      margin-bottom: 2rem; }
    .section__heading-h2.has__bg-red.has__marg {
      margin: 0 auto 2rem; }
  .section__heading-h2.has__bg-black {
    background-color: #080708;
    color: #fff;
    padding: 2rem;
    display: table;
    text-align: left; }
    .section__heading-h2.has__bg-black:nth-child(1) {
      margin-bottom: 2rem; }
    .section__heading-h2.has__bg-black:nth-child(2) {
      margin: 0; }
    .section__heading-h2.has__bg-black:nth-child(3) {
      margin-top: 2rem; }
    .section__heading-h2.has__bg-black.has__marg {
      margin: 0 auto 2rem; }

.section__heading-h4 {
  letter-spacing: 2.7px;
  line-height: 24px;
  color: #D0021B; }

.section__heading-h5 {
  line-height: 24px;
  text-align: center; }

.section__subheading {
  color: #fff;
  line-height: 3rem;
  text-align: center; }

.section__description {
  font-size: 18px;
  line-height: 3rem;
  color: #080708; }

.block__text {
  padding: 12rem 0; }
  .block__text .lead {
    line-height: 5rem;
    text-align: center;
    color: #080708; }
  .block__text.bg-black {
    background-color: #080708;
    padding: 14rem 0; }
    .block__text.bg-black .section__heading-h2 {
      margin-bottom: 25px; }
    .block__text.bg-black .section__subheading {
      margin-top: 25px; }
    .block__text.bg-black .section__heading-h5 {
      color: #9B9B9B;
      margin-bottom: 16px; }
    .block__text.bg-black .btn-cta {
      margin-top: 16px; }

@font-face {
  font-family: "Raleway Regular";
  src: url("../src/fonts/Raleway-Regular.woff2") format("woff2"), url("../src/fonts/Raleway-Regular.woff") format("woff"), url("../src/fonts/Raleway-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Raleway Light";
  src: url("../src/fonts/Raleway-Light.woff2") format("woff2"), url("../src/fonts/Raleway-Light.woff") format("woff"), url("../src/fonts/Raleway-Light.otf") format("opentype"); }

@font-face {
  font-family: "Larsseit";
  src: url("../src/fonts/Larsseit.woff2") format("woff2"), url("../src/fonts/Larsseit.woff") format("woff"), url("../src/fonts/Larsseit.otf") format("opentype"); }

@font-face {
  font-family: "Eczar Regular";
  src: url("../src/fonts/Eczar-Regular.woff2") format("woff2"), url("../src/fonts/Eczar-Regular.woff") format("woff"), url("../src/fonts/Eczar-Regular.otf") format("opentype"); }

.small {
  font-size: 1.3rem;
  line-height: 1.95rem;
  letter-spacing: 1px;
  font-family: "Eczar Regular"; }
  @media screen and (min-width: 576px) {
    .small {
      font-size: 1.8rem;
      line-height: 2.7rem; } }
  @media screen and (min-width: 768px) {
    .small {
      font-size: 1.6rem;
      line-height: 2.4rem; } }
  @media screen and (min-width: 992px) {
    .small {
      font-size: 1.4rem;
      line-height: 2.1rem; } }

.giant {
  font-family: "Larsseit";
  letter-spacing: 6px; }
  @media screen and (min-width: 0) {
    .giant {
      font-size: 2.5rem;
      line-height: 3.75rem; } }
  @media screen and (min-width: 768px) {
    .giant {
      font-size: 2.5rem;
      line-height: 3.75rem; } }
  @media screen and (min-width: 992px) {
    .giant {
      font-size: 3.5rem;
      line-height: 5.25rem; } }
  @media screen and (min-width: 1500px) {
    .giant {
      font-size: 4rem;
      line-height: 6rem; } }

p, .p, ul, ol {
  font-size: 1.6rem;
  letter-spacing: 2.4px;
  font-family: "Eczar Regular";
  line-height: 3.2rem; }
  @media (min-width: 768px) {
    p, .p, ul, ol {
      font-size: 1.8rem;
      line-height: 3.06rem; } }
  @media screen and (min-width: 768px) and (min-width: 576px) {
    p, .p, ul, ol {
      font-size: 2.5rem;
      line-height: 4.5rem; } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    p, .p, ul, ol {
      font-size: 2rem;
      line-height: 3.6rem; } }
  @media screen and (min-width: 768px) and (min-width: 992px) {
    p, .p, ul, ol {
      font-size: 1.8rem;
      line-height: 3.24rem; } }

h1, .h1, h1 > span, .h1 > span {
  font-size: 1.9rem;
  line-height: 2.09rem;
  font-family: "Larsseit";
  letter-spacing: 3px;
  margin-bottom: 1rem; }
  @media screen and (min-width: 576px) {
    h1, .h1, h1 > span, .h1 > span {
      font-size: 3.5rem;
      line-height: 3.85rem; } }
  @media screen and (min-width: 768px) {
    h1, .h1, h1 > span, .h1 > span {
      font-size: 5rem;
      line-height: 5.5rem; } }
  @media screen and (min-width: 992px) {
    h1, .h1, h1 > span, .h1 > span {
      font-size: 7rem;
      line-height: 7.7rem; } }
  @media screen and (min-width: 1500px) {
    h1, .h1, h1 > span, .h1 > span {
      font-size: 9rem;
      line-height: 9.9rem; } }
  @media (min-width: 992px) {
    h1, .h1, h1 > span, .h1 > span {
      letter-spacing: 6px; } }

h2, .h2 {
  font-size: 2rem;
  line-height: 4rem;
  font-family: "Larsseit";
  letter-spacing: 4px; }
  @media screen and (min-width: 576px) {
    h2, .h2 {
      font-size: 3.5rem;
      line-height: 3.85rem; } }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: 4.5rem;
      line-height: 4.95rem; } }
  @media screen and (min-width: 992px) {
    h2, .h2 {
      font-size: 6rem;
      line-height: 6.6rem; } }
  @media (min-width: 768px) {
    h2, .h2 {
      letter-spacing: 6px; } }

h3, .h3 {
  font-family: "Larsseit"; }
  @media screen and (min-width: 0) {
    h3, .h3 {
      font-size: 2.5rem;
      line-height: 2.75rem; } }
  @media screen and (min-width: 768px) {
    h3, .h3 {
      font-size: 2.5rem;
      line-height: 2.75rem; } }
  @media screen and (min-width: 992px) {
    h3, .h3 {
      font-size: 3.5rem;
      line-height: 3.85rem; } }
  @media screen and (min-width: 1500px) {
    h3, .h3 {
      font-size: 4rem;
      line-height: 4.4rem; } }

h4, .h4 {
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-family: "Larsseit";
  letter-spacing: 2.7px; }
  @media screen and (min-width: 576px) {
    h4, .h4 {
      font-size: 2.5rem;
      line-height: 3.75rem; } }
  @media screen and (min-width: 768px) {
    h4, .h4 {
      font-size: 2rem;
      line-height: 3rem; } }
  @media screen and (min-width: 992px) {
    h4, .h4 {
      font-size: 1.8rem;
      line-height: 2.7rem; } }

h5, .h5 {
  font-size: 1.8rem;
  line-height: 3.06rem;
  font-family: "Larsseit";
  letter-spacing: 2.7px; }
  @media screen and (min-width: 576px) {
    h5, .h5 {
      font-size: 2.5rem;
      line-height: 4.5rem; } }
  @media screen and (min-width: 768px) {
    h5, .h5 {
      font-size: 2rem;
      line-height: 3.6rem; } }
  @media screen and (min-width: 992px) {
    h5, .h5 {
      font-size: 1.8rem;
      line-height: 3.24rem; } }

a, .a {
  font-size: 1.8rem;
  line-height: 3.06rem;
  font-family: "Larsseit";
  letter-spacing: 2.7px; }
  @media screen and (min-width: 576px) {
    a, .a {
      font-size: 2.5rem;
      line-height: 4.5rem; } }
  @media screen and (min-width: 768px) {
    a, .a {
      font-size: 2rem;
      line-height: 3.6rem; } }
  @media screen and (min-width: 992px) {
    a, .a {
      font-size: 1.8rem;
      line-height: 3.24rem; } }

.uppercase {
  text-transform: uppercase; }

.smalllabel {
  font-weight: 600; }

.mainfooter .logo__footer {
  display: block;
  width: 25rem;
  max-width: 100%; }

.mainfooter .footer-top {
  padding: 6rem 0; }
  .mainfooter .footer-top .footer__btn .btn-cta:after {
    display: none; }

.mainfooter .footer-middle .footer__social h5,
.mainfooter .footer-middle .footer-pad h5 {
  width: 60%;
  padding: 1rem 0 15px;
  margin: 0;
  font-size: 12px;
  letter-spacing: 3.75px;
  line-height: 2rem;
  color: #080708;
  text-align: left !important;
  text-transform: uppercase; }
  .mainfooter .footer-middle .footer__social h5 a:hover,
  .mainfooter .footer-middle .footer-pad h5 a:hover {
    color: #D0021B;
    transition: width 0.4s ease; }

.mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer-pad span {
  font-size: 1.2rem;
  font-family: "Eczar";
  letter-spacing: 1px;
  line-height: 18px; }

.mainfooter .footer-middle .footer__social .section__heading > a, .mainfooter .footer-middle .footer__social span > a, .mainfooter .footer-middle .footer__social .footer-pad span > a, .mainfooter .footer-middle .footer-pad .footer__social span > a,
.mainfooter .footer-middle .footer-pad .section__heading > a,
.mainfooter .footer-middle .footer-pad .footer__social span > a,
.mainfooter .footer-middle .footer__social .footer-pad span > a,
.mainfooter .footer-middle .footer-pad span > a {
  padding: 1rem 0 1.5rem;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 3.75px;
  line-height: 2rem;
  color: #080708;
  text-align: left !important;
  text-transform: uppercase; }

.mainfooter .footer-middle .footer__social .footer__cert-list,
.mainfooter .footer-middle .footer-pad .footer__cert-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; }
  .mainfooter .footer-middle .footer__social .footer__cert-list .footer__cert-item,
  .mainfooter .footer-middle .footer-pad .footer__cert-list .footer__cert-item {
    margin: 1rem 2rem 1rem 0; }
    .mainfooter .footer-middle .footer__social .footer__cert-list .footer__cert-item img,
    .mainfooter .footer-middle .footer-pad .footer__cert-list .footer__cert-item img {
      width: 4.3rem;
      max-width: 100%; }

.mainfooter .footer-middle .footer__social-list .footer__social-item {
  display: inline-block;
  width: 28px;
  height: auto;
  margin-right: 5px; }
  .mainfooter .footer-middle .footer__social-list .footer__social-item .footer__social-link .svg-inline--fa {
    font-size: 2rem;
    color: #D0021B; }

.mainfooter .footer-middle .footer__list {
  list-style: none;
  padding: 0; }
  .mainfooter .footer-middle .footer__list .footer__items .footer__link {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    color: #080708;
    font-family: "Eczar";
    text-decoration: none; }
    .mainfooter .footer-middle .footer__list .footer__items .footer__link:hover {
      color: #D0021B;
      transition: width 0.4s ease; }

.mainfooter .footer-bottom {
  padding: 7rem 0 0; }
  .mainfooter .footer-bottom .list {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 768px) {
      .mainfooter .footer-bottom .list {
        flex-direction: row; } }
    .mainfooter .footer-bottom .list .list__item,
    .mainfooter .footer-bottom .list .lang-item {
      padding: 1rem;
      font-size: 12px;
      letter-spacing: 3.75px;
      line-height: 2rem;
      color: #080708;
      text-decoration: none;
      font-family: "Larsseit";
      text-transform: uppercase; }
      .mainfooter .footer-bottom .list .list__item a,
      .mainfooter .footer-bottom .list .lang-item a {
        font-size: 12px;
        letter-spacing: 3.75px;
        line-height: 2rem;
        color: #080708;
        text-decoration: none;
        font-family: "Larsseit";
        text-transform: uppercase; }
  .mainfooter .footer-bottom .minimal {
    background-image: url("/wp-content/themes/controlerl/assets/img/minimalMTL_logo.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: 29px;
    display: inline-block;
    padding-left: 27px;
    font-size: 1rem !important; }

.logo__header {
  width: 56%;
  max-width: 100%;
  margin-left: 2rem; }
  @media (min-width: 768px) {
    .logo__header {
      width: 30%; } }
  @media (min-width: 992px) {
    .logo__header {
      width: 43%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .logo__header {
      width: 20%;
      margin-left: 0; } }

img {
  width: 100%;
  height: auto; }

#controlerl-wrapper {
  padding-top: 4rem; }
  @media (min-width: 992px) {
    #controlerl-wrapper {
      padding-top: 8rem; } }
  #controlerl-wrapper img {
    max-width: 100%;
    width: 100%; }
  #controlerl-wrapper :focus {
    outline: none !important; }

.section__even img {
  max-width: 100%;
  object-fit: cover;
  display: block;
  margin-right: auto; }

.section__odd img {
  max-width: 100%;
  object-fit: cover;
  display: block;
  margin-left: auto; }

.section__odd h4 {
  text-align: center;
  margin-top: 25px; }

.section__odd a {
  text-align: center; }

.section__map {
  position: relative; }
  .section__map iframe {
    position: absolute;
    left: 0; }
  .section__map .col-lg-6:nth-child(2) {
    padding: 14rem 2rem; }
    .section__map .col-lg-6:nth-child(2) .block__contact {
      margin: 5%;
      text-align: center; }
      .section__map .col-lg-6:nth-child(2) .block__contact .section__heading-h5 {
        margin-bottom: 25px; }
      .section__map .col-lg-6:nth-child(2) .block__contact p {
        margin-bottom: 1rem; }
        .section__map .col-lg-6:nth-child(2) .block__contact p a {
          color: #080708; }

.has__position {
  position: relative; }
  .has__position h2 {
    position: relative;
    margin-bottom: -9rem; }
    @media (min-width: 768px) {
      .has__position h2 {
        position: relative;
        margin-bottom: -8.5rem; } }
    @media (min-width: 992px) {
      .has__position h2 {
        position: relative;
        margin-bottom: -11.5rem; } }

.module_block_text .giant p {
  font-family: "Larsseit";
  letter-spacing: 6px; }
  @media screen and (min-width: 0) {
    .module_block_text .giant p {
      font-size: 2.5rem;
      line-height: 3.75rem; } }
  @media screen and (min-width: 768px) {
    .module_block_text .giant p {
      font-size: 2.5rem;
      line-height: 3.75rem; } }
  @media screen and (min-width: 992px) {
    .module_block_text .giant p {
      font-size: 3.5rem;
      line-height: 5.25rem; } }
  @media screen and (min-width: 1500px) {
    .module_block_text .giant p {
      font-size: 4rem;
      line-height: 6rem; } }

.hero__banner-small.stack__2, .hero__banner-small.stack__4, .hero__banner-small.stack__5, .hero__banner-small.stack__8 {
  min-height: 0;
  padding-bottom: 0;
  padding-top: 10rem; }

.hero__banner-small.stack__2 .section__subheading, .hero__banner-small.stack__3 .section__subheading, .hero__banner-small.stack__4 .section__subheading, .hero__banner-small.stack__8 .section__subheading {
  width: 100%;
  display: block;
  margin: 3rem auto 0; }
  @media (min-width: 768px) {
    .hero__banner-small.stack__2 .section__subheading, .hero__banner-small.stack__3 .section__subheading, .hero__banner-small.stack__4 .section__subheading, .hero__banner-small.stack__8 .section__subheading {
      width: 70%;
      margin: 5rem auto 0; } }

.template-fabrication-mesure .stack__2 .section__heading, .template-fabrication-mesure .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-fabrication-mesure .stack__2 span,
.template-fabrication-mesure .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-fabrication-mesure .stack__2 span {
  max-width: 100%;
  margin-right: 0; }
  @media (min-width: 992px) {
    .template-fabrication-mesure .stack__2 .section__heading, .template-fabrication-mesure .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-fabrication-mesure .stack__2 span,
    .template-fabrication-mesure .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-fabrication-mesure .stack__2 span {
      max-width: 50rem;
      margin-right: 10rem; } }
  @media (min-width: 1200px) {
    .template-fabrication-mesure .stack__2 .section__heading, .template-fabrication-mesure .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-fabrication-mesure .stack__2 span,
    .template-fabrication-mesure .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-fabrication-mesure .stack__2 span {
      max-width: 50rem;
      margin-right: 16.5rem; } }

.template-fabrication-mesure .stack__2 .section__description {
  width: 100%; }
  @media (min-width: 1200px) {
    .template-fabrication-mesure .stack__2 .section__description {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: 0;
      max-width: 50rem; } }

.template-fabrication-mesure .stack__5 .card-deck .card-body h5 {
  font-size: 2.4rem; }

.template-realisations .hero__banner.stack__0 .section__subheading p {
  font-family: "Larsseit";
  letter-spacing: 0.6rem; }

.template-realisations .hero__banner-small.stack__4 {
  padding: 0;
  min-height: 60vh; }

.template-realisations .hero__banner-small.stack__2 {
  padding: 10rem 0; }

.front-page .module_block_text_img.stack__1 .has__position, .front-page .module_block_text_img.stack__3 .has__position {
  position: relative; }
  .front-page .module_block_text_img.stack__1 .has__position img, .front-page .module_block_text_img.stack__3 .has__position img {
    position: relative;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .front-page .module_block_text_img.stack__1 .has__position img, .front-page .module_block_text_img.stack__3 .has__position img {
        margin-bottom: 0 !important; } }
    @media (min-width: 1200px) {
      .front-page .module_block_text_img.stack__1 .has__position img, .front-page .module_block_text_img.stack__3 .has__position img {
        margin-bottom: -14.5rem !important; } }

.front-page .module_block_text_img.stack__1 .has__position h2 {
  position: relative;
  margin-bottom: -7rem; }
  @media (min-width: 768px) {
    .front-page .module_block_text_img.stack__1 .has__position h2 {
      margin-bottom: -9.5rem; } }
  @media (min-width: 992px) {
    .front-page .module_block_text_img.stack__1 .has__position h2 {
      margin-bottom: -16rem; } }

.front-page .module_block_text_img.stack__1 .section__description {
  margin-bottom: 4.5rem !important;
  max-width: 100%;
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .front-page .module_block_text_img.stack__1 .section__description {
      margin-bottom: 0 !important;
      max-width: 48rem; } }

.front-page .stack__2 {
  padding-top: 5rem; }
  @media (min-width: 1200px) {
    .front-page .stack__2 {
      padding-top: 15rem; } }

.front-page .stack__4 .has__position h2 {
  position: relative;
  margin-bottom: -9.5rem; }
  @media (min-width: 768px) {
    .front-page .stack__4 .has__position h2 {
      margin-bottom: -11.5rem; } }
  @media (min-width: 992px) {
    .front-page .stack__4 .has__position h2 {
      margin-bottom: -11.5rem; } }

.front-page .stack__5 .has__position h2 {
  position: relative;
  margin-bottom: -8.5rem; }
  @media (min-width: 768px) {
    .front-page .stack__5 .has__position h2 {
      margin-bottom: -11.5rem; } }
  @media (min-width: 992px) {
    .front-page .stack__5 .has__position h2 {
      margin-bottom: -16rem; } }

.front-page .stack__5 .list__partenaires {
  padding: 3rem 0; }
  @media (min-width: 992px) {
    .front-page .stack__5 .list__partenaires {
      padding: 6rem 0; } }
  .front-page .stack__5 .list__partenaires .slick-track {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center; }
    .front-page .stack__5 .list__partenaires .slick-track img {
      width: auto !important;
      max-width: 100%;
      display: block;
      margin: 0 auto; }

.template-conception .stack__0 {
  min-height: 120vh; }
  @media (min-width: 768px) {
    .template-conception .stack__0 {
      min-height: 100vh; } }

.template-conception .module_block_text_img.stack__3 .has__position {
  position: relative; }
  .template-conception .module_block_text_img.stack__3 .has__position img {
    position: relative;
    margin: 2rem 0 0 0; }
    @media (min-width: 992px) {
      .template-conception .module_block_text_img.stack__3 .has__position img {
        margin: 4rem 0 !important; } }
    @media (min-width: 1200px) {
      .template-conception .module_block_text_img.stack__3 .has__position img {
        margin: 4rem 0 -20rem 0 !important; } }

.template-conception .module_block_text_img.stack__3 .section__description {
  max-width: 48rem;
  padding-top: 3rem; }

.template-conception .stack__2,
.template-conception .stack__4 {
  padding-top: 5rem; }
  @media (min-width: 1200px) {
    .template-conception .stack__2,
    .template-conception .stack__4 {
      padding-top: 20rem; } }

.template-conception .stack__3:nth-child(even) {
  padding: 2.5rem 0; }
  @media (min-width: 992px) {
    .template-conception .stack__3:nth-child(even) {
      padding: 5rem 0; } }

.template-choisir-controlerl .stack__2 .section__heading, .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-choisir-controlerl .stack__2 span,
.template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-choisir-controlerl .stack__2 span {
  max-width: 100%;
  margin-right: 0 !important; }
  @media (min-width: 992px) {
    .template-choisir-controlerl .stack__2 .section__heading, .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-choisir-controlerl .stack__2 span,
    .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-choisir-controlerl .stack__2 span {
      max-width: 50rem;
      margin-right: 1.5rem !important; } }
  @media (min-width: 1200px) {
    .template-choisir-controlerl .stack__2 .section__heading, .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-choisir-controlerl .stack__2 span,
    .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-choisir-controlerl .stack__2 span {
      max-width: 50rem;
      margin-right: 6.5rem !important; } }

.template-choisir-controlerl .stack__3 {
  padding: 10rem 0; }

.template-choisir-controlerl .hero__banner-small.stack__4 {
  padding: 10rem 0; }
  .template-choisir-controlerl .hero__banner-small.stack__4 h2 {
    font-size: 4rem; }

.template-conception .stack__2 .section__heading, .template-conception .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-conception .stack__2 span,
.template-conception .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-conception .stack__2 span, .template-choisir-controlerl .stack__2 .section__heading, .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-choisir-controlerl .stack__2 span,
.template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-choisir-controlerl .stack__2 span {
  max-width: 100%;
  margin-right: 0; }
  @media (min-width: 992px) {
    .template-conception .stack__2 .section__heading, .template-conception .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-conception .stack__2 span,
    .template-conception .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-conception .stack__2 span, .template-choisir-controlerl .stack__2 .section__heading, .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-choisir-controlerl .stack__2 span,
    .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-choisir-controlerl .stack__2 span {
      max-width: 50rem;
      margin-right: 3.5rem; } }
  @media (min-width: 1200px) {
    .template-conception .stack__2 .section__heading, .template-conception .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-conception .stack__2 span,
    .template-conception .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-conception .stack__2 span, .template-choisir-controlerl .stack__2 .section__heading, .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-choisir-controlerl .stack__2 span,
    .template-choisir-controlerl .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-choisir-controlerl .stack__2 span {
      max-width: 50rem;
      margin-right: 8rem; } }

.template-conception .stack__2 .section__description,
.template-choisir-controlerl .stack__2 .section__description {
  width: 100%; }
  @media (min-width: 1200px) {
    .template-conception .stack__2 .section__description,
    .template-choisir-controlerl .stack__2 .section__description {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: 0;
      max-width: 50rem; } }

.template-conception .stack__4 .section__description,
.template-choisir-controlerl .stack__4 .section__description {
  width: 100%; }
  @media (min-width: 992px) {
    .template-conception .stack__4 .section__description,
    .template-choisir-controlerl .stack__4 .section__description {
      width: 80%;
      display: block;
      margin-right: auto;
      margin-left: 0; } }

.template-conception .stack__4 .section__description:nth-child(even),
.template-choisir-controlerl .stack__4 .section__description:nth-child(even) {
  width: 100;
  text-align: center;
  margin: 0 auto; }

.template-champs-dapplication-1 .stack__2 .section__heading, .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-champs-dapplication-1 .stack__2 span,
.template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-champs-dapplication-1 .stack__2 span,
.template-champs-dapplication-2 .stack__2 .section__heading,
.template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-2 .stack__2 span,
.template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-2 .stack__2 span,
.template-champs-dapplication-3 .stack__2 .section__heading,
.template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-3 .stack__2 span,
.template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-3 .stack__2 span,
.template-champs-dapplication-4 .stack__2 .section__heading,
.template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-4 .stack__2 span,
.template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-4 .stack__2 span,
.template-champs-dapplication-5 .stack__2 .section__heading,
.template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-5 .stack__2 span,
.template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-5 .stack__2 span {
  font-size: 2.5rem !important; }
  @media (min-width: 768px) {
    .template-champs-dapplication-1 .stack__2 .section__heading, .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .section__heading,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .section__heading,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .section__heading,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .section__heading,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-5 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-5 .stack__2 span {
      font-size: 4rem; } }

.template-champs-dapplication-1 .hero__banner-small.stack__3,
.template-champs-dapplication-2 .hero__banner-small.stack__3,
.template-champs-dapplication-3 .hero__banner-small.stack__3,
.template-champs-dapplication-4 .hero__banner-small.stack__3,
.template-champs-dapplication-5 .hero__banner-small.stack__3 {
  padding: 10rem 0; }
  .template-champs-dapplication-1 .hero__banner-small.stack__3 h2,
  .template-champs-dapplication-2 .hero__banner-small.stack__3 h2,
  .template-champs-dapplication-3 .hero__banner-small.stack__3 h2,
  .template-champs-dapplication-4 .hero__banner-small.stack__3 h2,
  .template-champs-dapplication-5 .hero__banner-small.stack__3 h2 {
    font-size: 4rem; }

.template-champs-dapplication-1 .hero__banner.stack__0 .section__subheading p,
.template-champs-dapplication-2 .hero__banner.stack__0 .section__subheading p,
.template-champs-dapplication-3 .hero__banner.stack__0 .section__subheading p,
.template-champs-dapplication-4 .hero__banner.stack__0 .section__subheading p,
.template-champs-dapplication-5 .hero__banner.stack__0 .section__subheading p {
  font-family: "Larsseit";
  letter-spacing: 0.6rem; }

.template-champs-dapplication-1 .stack__3,
.template-champs-dapplication-2 .stack__3,
.template-champs-dapplication-3 .stack__3,
.template-champs-dapplication-4 .stack__3,
.template-champs-dapplication-5 .stack__3 {
  padding: 0;
  min-height: 60vh; }

.template-champs-dapplication-1 .stack__2 .section__heading, .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-champs-dapplication-1 .stack__2 span,
.template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-champs-dapplication-1 .stack__2 span,
.template-champs-dapplication-2 .stack__2 .section__heading,
.template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-2 .stack__2 span,
.template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-2 .stack__2 span,
.template-champs-dapplication-3 .stack__2 .section__heading,
.template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-3 .stack__2 span,
.template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-3 .stack__2 span,
.template-champs-dapplication-4 .stack__2 .section__heading,
.template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-4 .stack__2 span,
.template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-4 .stack__2 span,
.template-champs-dapplication-5 .stack__2 .section__heading,
.template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer__social span,
.mainfooter .footer-middle .footer__social .template-champs-dapplication-5 .stack__2 span,
.template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer-pad span,
.mainfooter .footer-middle .footer-pad .template-champs-dapplication-5 .stack__2 span {
  font-size: 4rem;
  max-width: 100%; }
  @media (min-width: 768px) {
    .template-champs-dapplication-1 .stack__2 .section__heading, .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .section__heading,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .section__heading,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .section__heading,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .section__heading,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-5 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-5 .stack__2 span {
      width: 33rem; } }
  @media (min-width: 992px) {
    .template-champs-dapplication-1 .stack__2 .section__heading, .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .section__heading,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .section__heading,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .section__heading,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .section__heading,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-5 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-5 .stack__2 span {
      width: 45rem; } }
  @media (min-width: 1200px) {
    .template-champs-dapplication-1 .stack__2 .section__heading, .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-1 .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .template-champs-dapplication-1 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .section__heading,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-2 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-2 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .section__heading,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-3 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-3 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .section__heading,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-4 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-4 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .section__heading,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer__social span,
    .mainfooter .footer-middle .footer__social .template-champs-dapplication-5 .stack__2 span,
    .template-champs-dapplication-5 .stack__2 .mainfooter .footer-middle .footer-pad span,
    .mainfooter .footer-middle .footer-pad .template-champs-dapplication-5 .stack__2 span {
      width: 50rem; } }

.template-champs-dapplication-1 .stack__2 .section__description,
.template-champs-dapplication-2 .stack__2 .section__description,
.template-champs-dapplication-3 .stack__2 .section__description,
.template-champs-dapplication-4 .stack__2 .section__description,
.template-champs-dapplication-5 .stack__2 .section__description {
  max-width: 50rem;
  margin-right: 0;
  margin-left: auto; }

.template-champs-dapplication-1 .module_champs_application .has__position,
.template-champs-dapplication-2 .module_champs_application .has__position,
.template-champs-dapplication-3 .module_champs_application .has__position,
.template-champs-dapplication-4 .module_champs_application .has__position,
.template-champs-dapplication-5 .module_champs_application .has__position {
  position: relative; }
  .template-champs-dapplication-1 .module_champs_application .has__position h2,
  .template-champs-dapplication-2 .module_champs_application .has__position h2,
  .template-champs-dapplication-3 .module_champs_application .has__position h2,
  .template-champs-dapplication-4 .module_champs_application .has__position h2,
  .template-champs-dapplication-5 .module_champs_application .has__position h2 {
    position: relative;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .template-champs-dapplication-1 .module_champs_application .has__position h2,
      .template-champs-dapplication-2 .module_champs_application .has__position h2,
      .template-champs-dapplication-3 .module_champs_application .has__position h2,
      .template-champs-dapplication-4 .module_champs_application .has__position h2,
      .template-champs-dapplication-5 .module_champs_application .has__position h2 {
        margin-bottom: -3rem; } }
    @media (min-width: 992px) {
      .template-champs-dapplication-1 .module_champs_application .has__position h2,
      .template-champs-dapplication-2 .module_champs_application .has__position h2,
      .template-champs-dapplication-3 .module_champs_application .has__position h2,
      .template-champs-dapplication-4 .module_champs_application .has__position h2,
      .template-champs-dapplication-5 .module_champs_application .has__position h2 {
        margin-bottom: -9rem; } }

.block__text.stack__1 {
  padding-bottom: 5rem; }

.module_block_text_img.stack__2, .module_block_text_img.stack__4 {
  padding-top: 5rem; }

.template-contact .stack__0 .section__heading-h2,
.template-soumission .stack__0 .section__heading-h2,
.politique-de-confidentialite .stack__0 .section__heading-h2 {
  font-size: 1.9rem; }
  @media (min-width: 576px) {
    .template-contact .stack__0 .section__heading-h2,
    .template-soumission .stack__0 .section__heading-h2,
    .politique-de-confidentialite .stack__0 .section__heading-h2 {
      font-size: 3.5rem; } }
  @media (min-width: 768px) {
    .template-contact .stack__0 .section__heading-h2,
    .template-soumission .stack__0 .section__heading-h2,
    .politique-de-confidentialite .stack__0 .section__heading-h2 {
      font-size: 5rem; } }
  @media (min-width: 992px) {
    .template-contact .stack__0 .section__heading-h2,
    .template-soumission .stack__0 .section__heading-h2,
    .politique-de-confidentialite .stack__0 .section__heading-h2 {
      font-size: 6rem; } }

.template-soumission .stack__1 {
  padding: 3.5rem 0; }
  @media (min-width: 768px) {
    .template-soumission .stack__1 {
      padding: 5rem 0; } }
  @media (min-width: 992px) {
    .template-soumission .stack__1 {
      padding: 10rem 0; } }

.template-contact .stack__2 .section__heading-h2 {
  font-size: 1.9rem; }
  @media (min-width: 768px) {
    .template-contact .stack__2 .section__heading-h2 {
      font-size: 3.5rem; } }
  @media (min-width: 992px) {
    .template-contact .stack__2 .section__heading-h2 {
      font-size: 4rem; } }

.single-realisations .stack__1 .section__description {
  width: 100%; }
  @media (min-width: 1200px) {
    .single-realisations .stack__1 .section__description {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: 0;
      max-width: 50rem; } }

.single-realisations .module_retour_page_realisation {
  padding: 3rem 0; }
  @media (min-width: 992px) {
    .single-realisations .module_retour_page_realisation {
      padding: 6rem 0; } }
  .single-realisations .module_retour_page_realisation .btn {
    width: 100%;
    font-size: 1.5rem; }
    @media (min-width: 768px) {
      .single-realisations .module_retour_page_realisation .btn {
        width: 50%; } }
    @media (min-width: 992px) {
      .single-realisations .module_retour_page_realisation .btn {
        width: 45%; } }

.single-realisations .stack__2 .section__description {
  width: 100%; }
  @media (min-width: 1200px) {
    .single-realisations .stack__2 .section__description {
      width: 100%;
      display: block;
      margin-right: auto;
      margin-left: 0;
      max-width: 50rem; } }

.single-realisations .stack__5 {
  padding: 0;
  min-height: 60vh; }

.single-realisations .module_prev_next {
  padding: 2rem 0; }
  .single-realisations .module_prev_next .btn_next_prev {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 2rem 0; }
    @media (min-width: 768px) {
      .single-realisations .module_prev_next .btn_next_prev {
        flex-direction: row; } }
    .single-realisations .module_prev_next .btn_next_prev .btn_next,
    .single-realisations .module_prev_next .btn_next_prev .btn_previous {
      margin: 2rem 0; }
      @media (min-width: 768px) {
        .single-realisations .module_prev_next .btn_next_prev .btn_next,
        .single-realisations .module_prev_next .btn_next_prev .btn_previous {
          margin: 0 4rem; } }

.template_politique .stack__1 {
  padding: 25px 0; }
  @media (min-width: 768px) {
    .template_politique .stack__1 {
      padding: 50px 0; } }
  @media (min-width: 992px) {
    .template_politique .stack__1 {
      padding: 100px 0; } }

.error404 .block__cta {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  margin-top: 2rem; }

.custom-manufacturing .stack__2 .section__heading, .custom-manufacturing .stack__2 .mainfooter .footer-middle .footer__social span, .mainfooter .footer-middle .footer__social .custom-manufacturing .stack__2 span,
.custom-manufacturing .stack__2 .mainfooter .footer-middle .footer-pad span, .mainfooter .footer-middle .footer-pad .custom-manufacturing .stack__2 span {
  max-width: 50rem;
  margin-right: 24.5rem !important; }

.module_partenaire .list__partenaires .slick-list img {
  width: auto !important;
  max-width: 100%;
  display: block;
  margin: 0 auto; }

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.flex-end {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  @media (min-width: 768px) {
    .flex-end {
      justify-content: flex-end; } }

.u-p-tiny {
  padding: 25px 0; }

.u-p-small {
  padding: 50px 0;
  position: relative; }

.u-p-large {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .u-p-large {
      padding: 75px 0; } }

.u-p-huge {
  padding: 3.5rem 0; }
  @media (min-width: 768px) {
    .u-p-huge {
      padding: 5rem 0; } }
  @media (min-width: 992px) {
    .u-p-huge {
      padding: 10rem 0; } }

.u-pt-tiny {
  padding-top: 25px; }

.u-pt-small {
  padding-top: 50px; }

.u-pt-large {
  padding-top: 75px; }

.u-pt-huge {
  padding-top: 100px; }

.u-mb-1 {
  margin-bottom: 10px; }

.u-mb-2 {
  margin-bottom: 20px; }

.u-mb-3 {
  margin-bottom: 30px; }

.hr {
  background-color: #D0021B;
  height: 3px;
  width: 28px;
  display: inline-block;
  margin: 0; }
