.card-deck {
    margin: 5rem 0;
    justify-content: flex-start;
    .has__padding {
        padding-bottom: 3rem;
        @include media-breakpoint-up(md) {
            padding-bottom: 4rem; 
        }
    }
    .card {
        border: none;
        background-color: $white;
        margin-bottom: 3rem;
        border-radius: 0;
        &.has__full-height {
            height: 100%;
        }
        .card-body {
            padding: 4rem 3rem;
            h5 {	
                font-size: 2rem;	
                letter-spacing: 0.2rem;	
                line-height: 3rem;
                @include media-breakpoint-up(xl) {
                    font-size: 2.3rem;
                }
            }
            .card-lead,
            .card-description
              {
                color: $gray;
                font-size: 1.4rem !important;
                letter-spacing: 0.1rem !important;
                line-height: 1.5rem !important;
            }
            .card-text {
                font-size: 1.4rem !important;
                letter-spacing: 0.1rem !important;
                line-height: 1.5rem !important;
            }
        }
        .card-bottom {
            padding:5rem;
            .btn {
                padding: 0;
                text-align: left;
            }
        }
    }
}


.card {
    border: none;
    .card-wrapper {
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 4rem;
        }
        .card-img-top {
            margin-bottom: 4rem;
            object-fit: cover;
        }
        .card-icon {
            width: 8rem;
            height: 8rem;
            display: block;
            margin: 0 auto;
            .icon {
                max-width: 100%;
                object-fit: cover;
            }
        }
        .card-box {
            .section__heading-h4 {
                margin: 2rem auto 3rem;
                text-align: center;
            }
            .card-description {
                p {
                    font-size: $default-font-size;
                letter-spacing: 0.1rem;
                line-height: 2.1rem;
                text-align: center;
                }
            }
            .btn {
                color : $black;
                padding : 0;
                text-align : left;
            }
        }
    }
}

.icon-box{
    .icon-box-icon {
        width: 8rem;
        height: 8rem;
        display: block;
        margin: 0 auto;
        img {
            max-width: 100%;
            object-fit: cover;
        }
        
    }
    .icon-box-title {
        h4 {
            margin: 2rem auto 3rem;
            text-align: center;
        }
    }
}
