.u-p-tiny {
    padding: 25px 0;
}

.u-p-small {
    padding: 50px 0;

    position: relative;
}

.u-p-large {
    padding: 35px 0;
    @include media-breakpoint-up(md) {
        padding: 75px 0;
    }
}

.u-p-huge {
    padding: 3.5rem 0;
    @include media-breakpoint-up(md) {
        padding: 5rem 0; 
    }
    @include media-breakpoint-up(lg) {
        padding: 10rem 0; 
    }
}

.u-pt-tiny {
    padding-top: 25px;
}

.u-pt-small {
    padding-top: 50px;
}

.u-pt-large {
    padding-top: 75px;
}

.u-pt-huge {
    padding-top: 100px;
}

.u-mb-1 {
    margin-bottom: 10px;
}

.u-mb-2 {
    margin-bottom: 20px;
}


.u-mb-3 {
    margin-bottom: 30px;
}


.hr {
    background-color: $red;
    height: 3px;
    width: 28px;
    display: inline-block;
    margin: 0;    
}