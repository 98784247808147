.mouse-container{
    display: none;
  @include media-breakpoint-up(md) {
    position:absolute;
    display:block;
    margin: 2rem auto;
    bottom: 2rem;
    left: 49%;
    height: 5rem;
    transform: translateX(-50%);
  }
  .mouse{
    position:relative;
    margin:0 auto;
    display:block;
    width:3rem;
    height:5rem;
    border: solid 2px $white;
    border-radius: 25px;
   -webkit-animation: scroll-mouse 1.5s; 
   animation: scroll-mouse 1.5s;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   -webkit-animation-timing-function:ease;
   animation-timing-function:ease;
    
    .scroll-down{
      display:block;
      width:2px;
      height:15px;
      background:$white;
      border-radius:50%;
      margin:15% auto auto auto;
      
     -webkit-animation: scroll-inner 1.5s; 
     animation: scroll-inner 1.5s;
     -webkit-animation-iteration-count: infinite;
     animation-iteration-count: infinite;
     -webkit-animation-timing-function:ease;
     animation-timing-function:ease
        
    }
  }
}





@-webkit-keyframes scroll-inner {
    from {margin-top: 15%;}
    to {margin-top: 50%;}
}
@keyframes scroll-inner {
    from {margin-top: 15%;}
    to {margin-top: 50%;}
}
@-webkit-keyframes scroll-mouse {
    from {margin-top: 0;}
    to {margin-top: 15px;}
}
@keyframes scroll-mouse {
    from {margin-top: 0;}
    to {margin-top: 15px;}
}
