.logo__header {
    width: 56%;
    max-width: 100%;
    margin-left: 2rem;
    @include media-breakpoint-up(md) {
        width: 30%;
    }
    @include media-breakpoint-up(lg) {
        width: 43%;
        margin-left: 0;
    }
    @include media-breakpoint-up(xl) {
        width: 20%;
        margin-left: 0;
    }
}