.gform_wrapper {
  .validation_error {
    color: $red;
    padding: 5rem 0;
  }
  img{
    &.gform_ajax_spinner {
      display: none !important;
     }
  }
  

.gform_body {
    @extend .container;
    padding: 3rem 0 0;
    .gform_fields {
      @extend .row;
      padding: 0;
      margin: 0;
      list-style: none;
      
      #field_1_11, #field_1_12,#field_1_13, #field_1_3, #field_1_4, #field_1_5, #field_1_6,
      #field_2_1, #field_2_2, #field_2_3, #field_2_4, #field_2_5, #field_2_6, 
      #field_3_1, #field_3_2,  #field_3_3,  #field_3_4, #field_3_5, #field_3_6,
      #field_5_11, #field_5_12, #field_5_4, #field_5_6,
      #field_6_1, #field_6_2, #field_6_3, #field_6_4, #field_6_5, #field_6_6, 
      #field_8_1, #field_8_2, #field_8_3, #field_8_4, #field_8_5, #field_8_6{
        .gfield_label {
          display: none;
        }
        @extend .col-md-6;
        padding: 1rem;
            .ginput_container {
                input {
                    width: 100% ;
                    padding: 1rem;
                }
            }
            
              .validation_message {
              color: $red !important;
            
          }
      }
      
      #field_1_8,
      #field_2_7,
      #field_3_7,
      #field_5_8,
      #field_6_7,
      #field_8_7 {
        .gfield_label {
          display: none;
        }
        @extend .col-md-12;
        padding: 1rem;
            .ginput_container {
                textarea {
                    width: 100% ;
                    padding: 1rem;
                }
            }
             
              .validation_message {
              color: $red !important;
           
          }
      }
     // #field_2_7, #field_2_8, #field_2_9 {
     //   @include media-breakpoint-up(md) {
       //   position: relative;
       //   top: -69px;
      //  }
     // }
     #field_3_8,
     #field_6_8,
     #field_2_8,
     #field_8_8 {
        .ginput_container  {
            input#input_3_8,
            input#input_6_8,
            input#input_2_8,
            input#input_8_8 {
              color: white;
              margin-bottom: 2rem;
          }
        }
        
     }
     .ginput_container_fileupload {
       padding: 1rem;
     }
    }  
  }


  .gform_footer {
    padding: 10px; 
    input[type=submit] {
      font-size     : $default-font-size;
      letter-spacing: 0.3rem;
      line-height   : 1.8rem;
      border-radius   : 0;
      position: relative;
      text-transform: uppercase;
      white-space: normal;
      max-width: 100%;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;
      background-color: $red;
      padding: 1rem;
      @include media-breakpoint-up(lg) {
        letter-spacing: 0.5rem;
      }
      &:focus,
      &:active,
      &:visited {
        outline     : none;
       }
      &:after {
        content: "";
        position: absolute;
        bottom: -1rem;
        z-index: 1;
        left: 0;
        width: 2.8rem !important;
        height: 0.3rem;
        background-color: $red !important;
        -webkit-transition: width 0.3s ease !important;
        transition: width 0.3s ease !important;
        
    }
    &:hover::after {
        width: 100% !important;
        -webkit-transition: width 0.3s ease !important;
        transition: width 0.3s ease !important;
      }
    }
  }
}

.gform_confirmation_wrapper  {
  padding: 10rem 0 !important;
    .gform_confirmation_message {
      color: $white !important;
    }  
}