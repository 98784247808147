.scrolltop {
	display:none;
	width:100%;
	margin:0 auto;
	position:fixed;
	bottom:55px;
    right:0;	
    .scroll {
        position:absolute;
        right:0;
        bottom:-45px;
        padding:2rem;
        text-align: center;
        margin: 0 0 0 0;
        cursor:pointer;
        transition: 0.5s;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s; 
        transform: translate(-50%, -50%);	
        	
        img {
            width: 5rem;
            height: auto;
            max-width: 100%;
            object-fit: cover;
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
}