//////////////////////////////////---TYPOGRAPHY MIXIN CONFIGS---///////////////////////////////

$breakpoints: (


        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1500px



);

//Second value is line-height. 
//Note: Although line-height values can be defined using any valid CSS unit (percentages, pixels, ems, etc.), 
//“unitless” values are recommended and preferred 
//in order to avoid unexpected results due to inheritance.

//A ratio of 1.5 is used between font-sizes and line-height



$h1-font-sizes: (
        null : (1.9rem, 1.9*1.1rem),
        sm : (3.5rem, 3.5*1.1rem),
        md : (5rem, 5*1.1rem),
        lg : (7rem, 7*1.1rem),
        xl : (9rem, 9*1.1rem)
);

$h2-font-sizes: (
    null : (2rem, 2*2rem),
    sm : (3.5rem, 3.5*1.1rem),
    md : (4.5rem, 4.5*1.1rem),
    lg : (6.0rem, 6.0*1.1rem)
);

$h3-font-sizes: (
    xs : (2.5rem, 2.5*1.1rem),
    md : (2.5rem, 2.5*1.1rem),
    lg : (3.5rem, 3.5*1.1rem),
    xl : (4rem, 4*1.1rem)
);

$h4-font-sizes: (
    null : (1.8rem, 1.8*1.5rem),
    sm : (2.5rem, 2.5*1.5rem),
    md : (2.0rem, 2.0*1.5rem),
    lg : (1.8rem, 1.8*1.5rem)
);

$giant-font-sizes: (
        xs : (2.5rem, 2.5*1.5rem),
        md : (2.5rem, 2.5*1.5rem),
        lg : (3.5rem, 3.5*1.5rem),
        xl : (4rem, 4*1.5rem)
);

$psmall-font-sizes: (
        null : (1.3rem, 1.3*1.5rem),
        sm : (1.8rem, 1.8*1.5rem),
        md : (1.6rem, 1.6*1.5rem),
        lg : (1.4rem, 1.4*1.5rem)
);

$p-font-sizes: (
        null : (1.8rem, 1.8*1.7rem),
        sm : (2.5rem, 2.5*1.8rem),
        md : (2.0rem, 2.0*1.8rem),
        lg : (1.8rem, 1.8*1.8rem)
);


//assigning html elements to each sass map

.small {
  @include font-size($psmall-font-sizes);
  letter-spacing: 1px;
  font-family: $lead-font-family;
}


.giant {
  @include font-size($giant-font-sizes);
  font-family: $headings-font-family;
  letter-spacing: 6px;
}

p,.p, ul, ol, {
  font-size: 1.6rem;
  letter-spacing: 2.4px;
  font-family: $lead-font-family;
  line-height: 3.2rem;
  @include media-breakpoint-up(md) {
    @include font-size($p-font-sizes);
  }
  
}

h1,.h1, h1 > span, .h1>span {
  @include font-size($h1-font-sizes);
  font-family: $headings-font-family;
  letter-spacing: 3px;
  @include media-breakpoint-up(lg) {
    letter-spacing: 6px;
  }
  margin-bottom: 1rem;
}

h2,.h2 {
  @include font-size($h2-font-sizes);
  font-family: $headings-font-family;
  letter-spacing: 4px; 
  @include media-breakpoint-up(md) {
    letter-spacing: 6px;
  }
}

h3,.h3 {
  @include font-size($h3-font-sizes);
  font-family: $headings-font-family;
}

h4,.h4 {
  @include font-size($h4-font-sizes);
  font-family: $headings-font-family;
  letter-spacing: 2.7px;
}

h5,.h5 {
  @include font-size($p-font-sizes);
  font-family: $headings-font-family;
  letter-spacing: 2.7px;
}

a,.a {
    @include font-size($p-font-sizes);
    font-family: $headings-font-family;
    letter-spacing: 2.7px;
}

.uppercase {
  text-transform: uppercase;
}
.smalllabel {
  font-weight:600;
}
