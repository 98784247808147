/*Pour plus d'infos: https://github.com/xtianmiller/emergence.js

Les configurations sont dans client.js
*/


/*///////////// STYLES //////////////////////////
*/

.emerge[data-emergence=hidden] {
    opacity: 0;
}
.emerge[data-emergence=visible] {
    opacity: 1;
}


.emerge-top[data-emergence=hidden] {
    opacity: 0;
    transform: translateY(-50px);
}
.emerge-top[data-emergence=visible] {
    opacity: 1;
    transform: translateY(0px);
}


.emerge-right[data-emergence=hidden] {
    opacity: 0;
    transform: translateX(50px);
}
.emerge-right[data-emergence=visible] {
    opacity: 1;
    transform: translateX(0px);
}


.emerge-bottom[data-emergence=hidden] {
    opacity: 0;
    transform: translateY(50px);
}
.emerge-bottom[data-emergence=visible] {
    opacity: 1;
    transform: translateY(0px);
}


.emerge-left[data-emergence=hidden] {
    opacity: 0;
    transform: translateX(-50px);
}
.emerge-left[data-emergence=visible] {
    opacity: 1;
    transform: translateX(0px);
}




/*/////////////// SPEED //////////////////////////
*/

.emerge-speed-1 {
	transition: all 1s ease-out;
}


.emerge-speed-1pt5 {
	transition: all 1.5s ease-out;
}

.emerge-speed-2 {
	transition: all 2s ease-out;
}