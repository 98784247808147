@font-face {
    font-family:"Raleway Regular";
    src:url("../src/fonts/Raleway-Regular.woff2") format("woff2"),
        url("../src/fonts/Raleway-Regular.woff") format("woff"),
        url("../src/fonts/Raleway-Regular.otf") format("opentype");
}


@font-face {
    font-family:"Raleway Light";
    src:url("../src/fonts/Raleway-Light.woff2") format("woff2"),
        url("../src/fonts/Raleway-Light.woff") format("woff"),
        url("../src/fonts/Raleway-Light.otf") format("opentype");
}


@font-face {
    font-family:"Larsseit";
    src:url("../src/fonts/Larsseit.woff2") format("woff2"),
        url("../src/fonts/Larsseit.woff") format("woff"),
        url("../src/fonts/Larsseit.otf") format("opentype");
}


@font-face {
    font-family:"Eczar Regular";
    src:url("../src/fonts/Eczar-Regular.woff2") format("woff2"),
        url("../src/fonts/Eczar-Regular.woff") format("woff"),
        url("../src/fonts/Eczar-Regular.otf") format("opentype");
}
        
        