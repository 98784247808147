// COLORS
$white : #fff;
$black : #080708;
$red   : #D0021B;
$gray  : #9B9B9B;
$beige : #F5F5F5;


// Font 
$headings-font-family: "Larsseit";
$lead-font-family: "Eczar Regular";


//
// Headings
//



$default-font-size: 1.6rem;



$hamburger-menu-size: 6rem;
$hamburger-menu-border-radius: .12em;
$hamburger-menu-line-height: .2rem;
$hover-line-height: $hamburger-menu-line-height;
$hover-color: darken(white,10%);
$anim-duration: .4s;